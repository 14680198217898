/**
 * @file bible_utils.js
 * @description Functions for working with the Bible, verses, chapters, etc.
 */

import { bookNameToChapterCounts, verseCounts } from "../constants";
import { storage } from "../firebase";
import { ref, getDownloadURL } from "firebase/storage";

/**
 * Capitalize the first letter of a Bible book name. Handles books with numbers in their names.
 * @param book {string}
 * @returns {string}
 */
export function capitalizeBook(book) {
  if (book.charAt(0).match(/[0-9]/)) {
    // if the first character is a number, captialize the third character
    return (
      book.charAt(0) +
      book.charAt(1) +
      book.charAt(2).toUpperCase() +
      book.slice(3)
    );
  } else {
    // otherwise, capitalize the first character
    return book.charAt(0).toUpperCase() + book.slice(1);
  }
}

/**
 * Get a list of strings (verses) from a book and chapter.
 * Stored on the server in bible/{book name}/{chapter number}.txt
 * @param book {string}
 * @param chapter {number}
 * @returns {string[]}
 */
export function getListOfVerses(book, chapter) {
  // read from bible/{book name}/{chapter number}.txt
  let verses = [];
  let request = new XMLHttpRequest();
  request.open("GET", `bible/${book}/${chapter}.txt`, false);
  request.send(null);
  if (request.status === 200) {
    let text = request.responseText;
    verses = text.split("\n");
  }
  return verses;
}

/**
 * Get a list of reading portions from a list of books.
 * A reading portion is a list of two elements representing the start and end of the portion:
 * Each element is a [book, chapter], so a reading portion of a single book would be a list representing
 * the first and last chapters of a book.
 * @param books {string[]}
 * @returns {[string, number][][]}
 * @example
 * getReadingPortionsFromBooks(["Genesis", "Exodus"])
 * // returns [[["Genesis", 1], ["Genesis", 50]], [["Exodus", 1], ["Exodus", 40]]]
 */
export function getReadingPortionsFromBooks(books) {
  let reading_portions = [];
  for (let i = 0; i < books.length; i++) {
    let book = books[i];
    let book_reading_portion = [
      [book, 1],
      [book, bookNameToChapterCounts[book]],
    ];
    reading_portions.push(book_reading_portion);
  }
  return reading_portions;
}

/**
 * Get a list of verses and a copyright message from a book and chapter
 * @param book {string} should be fully lowercase
 * @param chapter {int}
 * @return {Promise<[string[], string]>}
 */
export async function getListOfVersesAndCopyrightMessage(book, chapter) {
  // read from bible/{book name}/{chapter number}.txt
  let verses = [];
  let copyrightMessage = "";
  const numberOfVersesInChapter = verseCounts[book][chapter - 1];
  // iterate 30 verses at a time and access the lsm api
  // for (let i = 0; i < numberOfVersesInChapter; i += 30) {
  //     const startVerse = i + 1;
  //     const endVerse = Math.min(i + 30, numberOfVersesInChapter);
  //     const link = "https://api.lsm.org/recver.php?" +
  //         "String=" + book + chapter + ":" + startVerse + "-" + endVerse +
  //         "&Out=json";
  //     console.log("Getting verses from " + link);
  //     const json = await getJSON(link);
  //     console.log("Got verses from " + link + ": " + json.verses.length);
  //     const versesSection = [];
  //     for (let j = 0; j < json.verses.length; j++) {
  //         versesSection.push(json.verses[j].text);
  //     }
  //     verses = verses.concat(versesSection);
  //     copyrightMessage = json.copyright;
  // }
  // read from firebase storage
  const storageRef = ref(storage, `bible/${book}/${chapter}.txt`);
  const url = await getDownloadURL(storageRef);
  console.log("Getting verses from " + url);
  const request = new XMLHttpRequest();
  request.open("GET", url, false);
  request.send(null);
  if (request.status === 200) {
    const text = request.responseText;
    verses = text.split("\n");
  }
  if (verses.length !== verseCounts[book][chapter - 1]) {
    console.error(
      "Got " +
        verses.length +
        " verses, but expected " +
        verseCounts[book][chapter - 1] +
        " for " +
        book +
        " " +
        chapter
    );
    throw new Error(
      "Got " +
        verses.length +
        " verses, but expected " +
        verseCounts[book][chapter - 1] +
        " for " +
        book +
        " " +
        chapter
    );
  }
  copyrightMessage =
    "Holy Bible Recovery Version (text-only edition) © " +
    "2022 Living Stream Ministry www.lsm.org";
  console.log("Got " + verses.length + " verses:\n" + verses.join("\n"));
  return [verses, copyrightMessage];
}
