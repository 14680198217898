import React from "react";
import { Button, Card, Col, Row } from "react-bootstrap";

export const HomePage = () => {
  return (
    <Row className={"container-fluid"}>
      <Col>
        <div className="row align-items-center justify-content-center text-center">
          <div className="col-2 d-none d-md-block" />
          <div className="col-12 col-md-8 align-items-center justify-content-center text-center align-middle">
            <h2>Welcome to the Bible Reading Challenges!</h2>
            <p>
              Jeremiah 15:16 says,{" "}
              <i>
                "Your words were found, and I ate them, and your words became to
                me The gladness and joy of my heart, for I am called by your
                name, O Jehovah, God of hosts."
              </i>
            </p>
            <p>
              Reading the Word of God is an essential part of the Christian
              life. It is through the Bible that we not only learn about God and
              His will, but also get to know Him personally. However, it can be
              difficult to read the Bible consistently. Bible Reading Challenges
              are a way to
              <b> read</b> the Bible with others,
              <b> encourage</b> one other to read the Bible consistently, and{" "}
              <b> get email updates</b> when it is time to read.
            </p>
            <p>
              The use of this site is free, and you can create a reading
              challenge for yourself and for others. The goal of this site is to
              encourage seeking Christians and offer access to tools with which
              to get to know the Word. The only data stored is your provided
              account information, and a record of what you have read. See our{" "}
              <a href="/privacy-policy">privacy policy</a> for more information.
            </p>
            <p>
              This site is a work in progress, and we are always looking for
              feedback. We are working on adding additional Bible versions and
              other tools to support learning and understanding the Bible. If
              you have any suggestions or feedback, please contact us at
              mail@memorize.life.
            </p>
            <Card className="text-bg-light mb-3">
              <Card.Body>
                <Card.Title>Bible Reading Challenges</Card.Title>
                <Card.Text>
                  Join or create a reading challenge to read the Bible with
                  others!
                </Card.Text>
                <Button href="/read" variant="primary">
                  Go to Challenges
                </Button>
              </Card.Body>
            </Card>
          </div>
          <div className="col-2 d-none d-md-block" />
        </div>
      </Col>
    </Row>
  );
};
