import React from "react";
import { Navbar, Nav, Button } from "react-bootstrap";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase";
import { logIn, logOut } from "../UserAuth";

const site_logo = require("../assets/media/logo192.png");

export const Header = () => {
  const [user, loading] = useAuthState(auth);
  return (
    <Navbar
      bg="light"
      expand="lg"
      style={{ paddingLeft: 10, paddingRight: 10, marginBottom: 20 }}
    >
      <Navbar.Brand className="ms-3 ms-lg-5" href="/">
        <img
          src={site_logo}
          alt={"memorize.life bible logo"}
          style={{
            width: "25px",
            height: "25px",
            display: "inline",
          }}
        />
        &nbsp;memorize.life
      </Navbar.Brand>
      <Navbar.Toggle />
      <Navbar.Collapse>
        <Nav className="me-auto mb-1 mb-lg-0 ms-3 ms-lg-1">
          <Nav.Item>
            <Nav.Link aria-current="page" href="/">
              Home
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="nav-item">
            <Nav.Link href="/read">Bible Reading Challenges</Nav.Link>
          </Nav.Item>
          <Nav.Item className="nav-item">
            <Nav.Link href="/leaderboard">Challenge Leaderboard</Nav.Link>
          </Nav.Item>
          <Nav.Item className="nav-item">
            <Nav.Link href="/profile">Profile</Nav.Link>
          </Nav.Item>
        </Nav>
        <div id="user-info" className="my-2 my-lg-0 mx-3 mx-lg-2">
          {loading ? (
            <div>Loading...</div>
          ) : user === null ? (
            <Button
              id="sign-in-with-google"
              variant="outline-primary"
              onClick={logIn}
            >
              Sign in with Google
            </Button>
          ) : (
            <>
              <div>Signed in as {user.displayName}</div>
              <Button variant="outline-primary" onClick={logOut}>
                Sign out
              </Button>
            </>
          )}
        </div>
      </Navbar.Collapse>
    </Navbar>
  );
};
