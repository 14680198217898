import React from "react";
import { Button, Card, Col, Row } from "react-bootstrap";

export const DataRequest = () => {
  return (
    <div>
      <Row className={"container-fluid"}>
        <Col xs={0} md={2}></Col>
        <Col xs={12} md={8}>
          <Row className={"container-fluid"}>
            <Col>
              <h2>Data Request</h2>
            </Col>
          </Row>

          <Row className={"container-fluid"}>
            <Col>
              <p>
                memorize.life stores the data you provide when you create an
                account and use the site. See our{" "}
                <a href="/privacy-policy">privacy policy</a> for more
                information. This data includes your email address, password,
                and reading challenge progress. We do not share any personal
                data with anyone besides what is visible in the group reading
                features, and we do not use it for any purpose other than to
                provide you with the services on this site. If you would like to
                change your displayed name, you can do so on the{" "}
                <a href="/profile">profile page</a>.
              </p>
              <p>
                If you would like to request a copy of the data we have stored
                for you, please use the button below. We will send you a copy of
                your data within 30 days.
              </p>
            </Col>
          </Row>

          <Row className={"container-fluid"}>
            <Col
              className={
                "justify-content-center align-content-center align-items-center text-center"
              }
            >
              {/* Button that sends an email to mail@memorize.life with subject line [Data Request] */}
              <p className={"text-muted"}>
                Send an email to mail@memorize.life with [Data Request] in the
                subject line
              </p>
              <Button href="mailto:mail@memorize.life?subject=[Data Request]">
                Request Data
              </Button>
            </Col>
          </Row>

          <br />

          <Row className={"container-fluid"}>
            <Col>
              <p>
                If you would like to delete your account and all associated
                data, please use the button below. We will delete your account
                and all associated data within 30 days.
              </p>
            </Col>
          </Row>

          <Row className={"container-fluid"}>
            <Col
              className={
                "justify-content-center align-content-center align-items-center text-center"
              }
            >
              {/* Button that sends an email to mail@memorize with subject line [Data Deletion] */}
              <p className={"text-muted"}>
                Send an email to mail@memorize.life with [Data Deletion] in the
                subject line
              </p>
              <Button href="mailto:mail@memorize.life?subject=[Data Deletion]">
                Delete Data
              </Button>
            </Col>
          </Row>
        </Col>
        <Col xs={0} md={2}></Col>
      </Row>
    </div>
  );
};
