/**
 * @file challenges.js
 * @description Functions for working with challenges.
 */

import { deleteDoc, doc, getDoc, setDoc } from "firebase/firestore";
import { getCurrentLocalDate, getValidDays } from "../../utils/date_utils";
import { bookNameToChapterCounts } from "../../constants";
import { createTemporaryUserFromEmail, getUIDfromEmail } from "../../firebase";

/**
 * Check if a given user (UID) is in a given challenge.
 * @param db {firebase.firestore.Firestore}
 * @param uid {string}
 * @param challengeID {string}
 * @returns {Promise<boolean>}
 */
export async function checkIfUserIsInChallenge(db, uid, challengeID) {
  const userChallengeDoc = await doc(
    db,
    "users",
    uid,
    "challenges",
    challengeID
  );
  const userChallengeDocSnapshot = await getDoc(userChallengeDoc);
  return !!userChallengeDocSnapshot.exists();
}

/**
 * Add a user (UID) to a challenge.
 * @param db {Firestore}
 * @param uid {string}
 * @param challengeID {string}
 * @returns {Promise<void>}
 */
export async function addUserToChallenge(db, uid, challengeID) {
  // assuming the user exists already, add them to the challenge
  // add the challenge to the user's list of challenges
  const userChallengeDocRef = doc(db, "users", uid, "challenges", challengeID);
  const challengeDocSnap = await getDoc(doc(db, "challenges", challengeID));
  if (!challengeDocSnap.exists()) {
    console.log(
      "Tried adding a user to a challenge where the challenge doesn't exist."
    );
  }
  await setDoc(userChallengeDocRef, {
    progress: "0".repeat(challengeDocSnap.data().reading_portions),
    emailUpdates: true,
  });
  // add the user to the challenge's list of users
  const challengeUserList = doc(db, "challenges", challengeID, "users", uid);
  await setDoc(challengeUserList, {});
}

/**
 * Remove a user (UID) from a challenge.
 * @param db {Firestore}
 * @param uid {string}
 * @param challengeID {string}
 * @returns {Promise<void>}
 */
export async function removeUserFromChallenge(db, uid, challengeID) {
  // remove the challenge from the user's list of challenges, and the user from the challenge's list of users
  const userChallengeInfo = doc(db, "users", uid, "challenges", challengeID);
  await deleteDoc(userChallengeInfo);
  // remove the user from the challenge's list of users
  const challengeUserList = doc(db, "challenges", challengeID, "users", uid);
  await deleteDoc(challengeUserList);
}

/**
 * Get the total chapters in a list of reading portions
 * @param readingPortions {[string, int][][]}
 * @return {number}
 */
export function getTotalChapters(readingPortions) {
  let totalChapters = 0;

  for (const [startBookAndChapter, endBookAndChapter] of readingPortions) {
    let currentBook = startBookAndChapter[0];
    let currentChapter = startBookAndChapter[1];

    while (
      currentBook !== endBookAndChapter[0] ||
      currentChapter !== endBookAndChapter[1]
    ) {
      currentChapter++;
      totalChapters++;

      if (currentChapter > bookNameToChapterCounts[currentBook]) {
        currentBook = Object.keys(bookNameToChapterCounts).find(
          (key) => bookNameToChapterCounts[key] === currentChapter - 1
        );
        currentChapter = 1;
      }
    }

    totalChapters++; // Include the last chapter
  }

  return totalChapters;
}

/**
 * Get the reading schedule based on the inputs of a challenge.
 * @param startDate {string}
 * @param endDate {string}
 * @param daysOfTheWeek {string[]}
 * @param readingPortions {[string, int][][]}
 * @param datesToSkip {Date[]}
 * @returns {*[]} A list of pairs. Each pair is a Date() and an array of chapters to read on that date.
 */
export function getDatesToChapters(
  startDate,
  endDate,
  daysOfTheWeek,
  readingPortions,
  datesToSkip
) {
  // return a list of pairs. Each pair is a Date() and an array of chapters to read on that date
  let validDays = getValidDays(startDate, endDate, daysOfTheWeek, datesToSkip);
  const totalChapters = getTotalChapters(readingPortions);
  // if end date was empty, then adjust validDays to be the correct length
  if (endDate === "") {
    validDays = validDays.slice(0, totalChapters);
  }
  const chaptersPerDay = totalChapters / validDays.length;

  let currentPortionIndex = 0;
  let [currentBook, currentChapter] = readingPortions[currentPortionIndex][0];
  // const [endBook, endChapter] = readingPortions[readingPortions.length - 1][1];
  const schedule = [];
  let chaptersRead = 0;
  let chaptersShouldHaveRead = 0;

  for (const validDay of validDays) {
    const chaptersToRead = [];
    // calculate how many chapters should be read on this day
    chaptersShouldHaveRead += chaptersPerDay;
    let chaptersToReadOnThisDay =
      Math.round(chaptersShouldHaveRead) - chaptersRead;
    for (let i = 0; i < chaptersToReadOnThisDay; i++) {
      chaptersToRead.push([currentBook, currentChapter]);
      currentChapter++;

      // Check if the current chapter exceeds the current portion's end chapter
      const [currentEndBook, currentEndChapter] =
        readingPortions[currentPortionIndex][1];
      if (
        currentBook === currentEndBook &&
        currentChapter > currentEndChapter
      ) {
        currentPortionIndex++;
        if (currentPortionIndex < readingPortions.length) {
          [currentBook, currentChapter] =
            readingPortions[currentPortionIndex][0];
        }
      }
    }
    chaptersRead += chaptersToRead.length;
    schedule.push([validDay, chaptersToRead]);
  }

  return schedule;
}

/**
 * Update get-emails property of user in a challenge.
 * @param db {firebase.firestore.Firestore}
 * @param uid {string}
 * @param challengeID {string}
 * @param getEmails {boolean}
 */
export async function updateGetEmails(db, uid, challengeID, getEmails) {
  if (db === null) return;
  if (uid === null) return;
  if (challengeID === null) return;
  const userChallengeInfo = doc(db, "users", uid, "challenges", challengeID);
  const oldDoc = await getDoc(userChallengeInfo);
  await setDoc(userChallengeInfo, {
    emailUpdates: getEmails,
    progress: oldDoc.data().progress,
  });
}

/**
 * Mark a user's reading as complete/incomplete for a given reading portion.
 * @param db {Firestore}
 * @param uid {string}
 * @param challengeID {string}
 * @param readingPortionIndex {int} Must be 1 indexed!!
 * @param complete {boolean}
 * @returns {Promise<boolean>}
 */
export async function markReadingPortion(
  db,
  uid,
  challengeID,
  readingPortionIndex,
  complete
) {
  if (readingPortionIndex == null || readingPortionIndex === 0 || uid == null) {
    return false;
  }
  // update the challenge progress in the database
  const userChallengeDocRef = await doc(
    db,
    "users",
    uid,
    "challenges",
    challengeID
  );
  const userChallengeDoc = await getDoc(userChallengeDocRef);
  if (!userChallengeDoc.exists()) {
    return false;
  }
  const userChallengeInfo = userChallengeDoc.data();
  const newChallengeProgress =
    userChallengeInfo.progress.substring(0, readingPortionIndex - 1) +
    (complete ? "1" : "0") +
    userChallengeInfo.progress.substring(readingPortionIndex);
  await setDoc(userChallengeDocRef, {
    progress: newChallengeProgress,
    emailUpdates: userChallengeInfo.emailUpdates,
    lastUpdated: Date.now(),
  });
  return true;
}

/**
 * Get last read date based on the progress and the schedule.
 * @param progress {string}
 * @param datesToChapters {[[Date, [string, int][]]]}
 */
export function getLastReadDate(progress, datesToChapters) {
  let lastReadDate = null;
  for (let i = 0; i < progress.length; i++) {
    if (progress[i] === "1") {
      lastReadDate = datesToChapters[i][0];
    }
  }
  return lastReadDate;
}

/**
 * Get percentage of challenge completed based on the progress and the schedule.
 * @param progress {string}
 * @param datesToChapters {[[Date, [string, int][]]]}
 */
export function getPercentageCompleted(progress, datesToChapters) {
  let chaptersRead = 0;
  for (let i = 0; i < progress.length; i++) {
    if (progress[i] === "1") {
      chaptersRead += 1;
    }
  }
  return (chaptersRead / datesToChapters.length) * 100;
}

/**
 * Get the most recent portion index that should be read. Returns 1 indexed.
 * @param datesToChapters {[[Date, [string, int][]]]}
 */
export function getMostRecentPortionIndex(datesToChapters) {
  let readingPortionClosest = null;
  for (let i = 0; i < datesToChapters.length; i++) {
    let isBeforeCurrentTime = datesToChapters[i][0] < getCurrentLocalDate();
    if (isBeforeCurrentTime) {
      readingPortionClosest = i + 1;
    }
  }
  return readingPortionClosest;
}

/**
 * Get the first unread portion index that should be read. Returns 1 indexed.
 * @param challengeProgress {string} e.g. "00001101"
 */
export function getFirstUnreadPortionIndex(challengeProgress) {
  let firstUnreadPortionIndex = null;
  for (let i = 0; i < challengeProgress.length; i++) {
    if (challengeProgress[i] === "0") {
      firstUnreadPortionIndex = i + 1;
      break;
    }
  }
  return firstUnreadPortionIndex;
}

/**
 * Add a participant to a challenge using their email.
 * @param db {Firestore}
 * @param challengeID {string}
 * @param email {string}
 * @returns {Promise<[boolean,string]>} [success, message]
 */
export async function addUserToChallengeByEmail(db, challengeID, email) {
  // validate email using regex
  const regex =
    "^([\\w-]+(?:\\.[\\w-]+)*)@((?:[\\w-]+\\.)*\\w[\\w-]{0,66})\\.([a-z]{2,6}(?:\\.[a-z]{2})?)$";
  if (!email.match(regex)) {
    return [false, "Invalid email address."];
  }
  // get user id from email
  const uid = await getUIDfromEmail(db, email);
  if (uid) {
    // check if the user is already in the challenge
    const inChallenge = await checkIfUserIsInChallenge(db, uid, challengeID);
    if (inChallenge) {
      return [false, "This user is already in the challenge."];
    } else {
      await addUserToChallenge(db, uid, challengeID);
      return [true, "User added to challenge."];
    }
  } else {
    // create a temporary user
    let tempUID = await createTemporaryUserFromEmail(db, email);
    // add the user to the challenge
    await addUserToChallenge(db, tempUID, challengeID);
    return [true, "User added to challenge."];
  }
}
