import { createContext, useContext, useEffect, useState } from "react";
import {
  GoogleAuthProvider,
  onAuthStateChanged,
  signInWithRedirect,
  signOut,
  signInWithPopup,
} from "firebase/auth";
import { auth, db } from "./firebase";
import { getUserDisplayName } from "./components/profile/profile_utils";

const userAuth = createContext();
const provider = new GoogleAuthProvider();

export function logIn() {
  if (process.env.REACT_APP_ENV === "development") {
    signInWithPopup(auth, provider);
    return;
  }
  signInWithRedirect(auth, provider);
}
export function logOut() {
  signOut(auth);
}

export function UserAuthContextProvider({ children }) {
  const [user, setUser] = useState(null);
  const [displayName, setDisplayName] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentuser) => {
      // console.log("Auth", currentuser);
      setUser(currentuser);
      (async () => {
        setDisplayName(await getUserDisplayName(db, currentuser));
      })();
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <userAuth.Provider
      value={{ user, logIn, logOut, displayName, setDisplayName }}
    >
      {children}
    </userAuth.Provider>
  );
}

export function useUserAuth() {
  return useContext(userAuth);
}
