import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { ChallengesList } from "./challenges/ChallengesList";
import { instantMeiliSearch } from "@meilisearch/instant-meilisearch";
import instantsearch from "instantsearch.js"; // yes they named the library this
import { configure, searchBox, hits } from "instantsearch.js/es/widgets";
import { createUserInDatabaseIfDoesNotExist, db } from "../firebase";
import { useUserAuth } from "../UserAuth";

export const ReadingChallenges = () => {
  const { user } = useUserAuth();
  const [oneTimeAction, setOneTimeAction] = useState(true);

  const searchClient = instantMeiliSearch(
    "https://ms-78d5223ffab7-3445.sfo.meilisearch.io",
    "9d18b97086d8cb723d8679b54b0fc7785264f1e1ef4092af8485d07151626b31"
  );
  const search = instantsearch({
    indexName: "challenge-index",
    searchClient,
  });
  document.addEventListener("DOMContentLoaded", () => {
    console.log("Populating Search Bar");
    search.addWidgets([
      configure({
        filters: "is_public = true",
      }),
      searchBox({
        container: "#searchbox",
      }),
      hits({
        container: "#hits",
        templates: {
          item: `
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title hit-name">{{#helpers.highlight}}{ "attribute": "challenge_name" }{{/helpers.highlight}}</h5>
                    <p class="card-text">{{#helpers.highlight}}{ "attribute": "books" }{{/helpers.highlight}}</p>
                    <a href="challenge?id={{_firestore_id}}" class="btn btn-primary">View Challenge</a>
                </div>
            </div>
            `,
        },
      }),
    ]);
    search.start();
    console.log("Starting Search Bar");
  });

  /**
   * Handle one-time actions that need to be performed when the page loads.
   * @returns {Promise<void>}
   * @constructor
   */
  const OneTimeAction = async () => {
    await createUserInDatabaseIfDoesNotExist(db, user);
    setOneTimeAction(false);
  };

  useEffect(() => {
    if (oneTimeAction) {
      OneTimeAction().then((r) => r);
    }
  }, []);

  return (
    <Row className={"container-fluid"}>
      <Col>
        <Row>
          <Col>
            <Row>
              <Col className={"col-2 d-none d-md-block"} />
              <Col className={"col-12 col-md-8"}>
                <h2>Bible Reading Challenges</h2>
              </Col>
              <Col className={"col-2 d-none d-md-block"} />
            </Row>
            <Row>
              <Col className={"col-2 d-none d-md-block"} />
              <Col
                className={
                  "col-12 col-md-8 bg-primary bg-opacity-25 rounded m-2 p-3"
                }
              >
                <Button
                  id="create-challenge-button"
                  variant="secondary"
                  href="create_challenge"
                >
                  Create a Reading Challenge
                </Button>
                <hr />
                <p>
                  Create a reading challenge to read the Bible with others!
                  Choose a book of the Bible to read, what days to read on, and
                  how fast to read. By sharing a link to your challenge, others
                  can join your challenge and read with you!
                </p>
              </Col>
              <Col className={"col-2 d-none d-md-block"} />
            </Row>
          </Col>
        </Row>
        <br />
        <Row>
          <Col className={"col-2 d-none d-md-block"} />
          <Col className="col-12 col-md-8">
            <h3>My Reading Challenges</h3>
            <div className="bg-primary bg-opacity-25 rounded m-2 p-3">
              <ChallengesList />
            </div>
          </Col>
          <Col className={"col-2 d-none d-md-block"} />
        </Row>
        <br />
        {/*TODO support meilisearch*/}
        <Row>
          <Col className={"col-2 d-none d-md-block"} />
          <Col className="col-12 col-md-8">
            <h3>Find a Public Reading Challenge</h3>
            <div className="bg-primary bg-opacity-25 rounded m-2 p-3">
              <div id="searchbox" className="container-fluid rounded m-2" />
              <div id="hits" className="rounded p-1" />
            </div>
          </Col>
          <Col className={"col-2 d-none d-md-block"} />
        </Row>
      </Col>
    </Row>
  );
};
