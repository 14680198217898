import React, { useEffect, useState } from "react";
import {
  capitalizeBook,
  getListOfVerses,
  getReadingPortionsFromBooks,
} from "../../utils/bible_utils";
import { useUserAuth, logIn } from "../../UserAuth";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { createUserInDatabaseIfDoesNotExist, db } from "../../firebase";
import { verseCounts } from "../../constants";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  addUserToChallenge,
  addUserToChallengeByEmail,
  getDatesToChapters,
  getFirstUnreadPortionIndex,
  getLastReadDate,
  getMostRecentPortionIndex,
  getPercentageCompleted,
  markReadingPortion,
  removeUserFromChallenge,
  updateGetEmails,
} from "./challenges";
import {
  getCurrentLocalDate,
  parseDateList,
  stringifyDateLocally,
} from "../../utils/date_utils";
import { Button, Col, Pagination, Row, Table, Collapse } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { AlertComponent } from "../AlertComponent";
import { Copyright } from "../bible/Copyright";
import { ScrollToTop } from "../utilities/ScrollToTop";
import { ChallengeLeaderboard } from "../leaderboard/ChallengeLeaderboard";
import QRCode from "react-qr-code";

const add_person_image = require("../../assets/media/person-add.svg").default;
const three_dots = require("../../assets/media/three-dots.svg").default;

// TODO make ChallengeAuthContext, which provides:
//  challengeData, challengeParticipants, datesToChapters, loadedChallenge, loadedParticipants, loadedDatesToChapters
//  then separate out the components inside here into three files and use the context

export const Challenge = (props) => {
  const queryParams = new URLSearchParams(props.location.search);
  const challengeID = queryParams.has("id") ? queryParams.get("id") : null;
  const queryParamUID = queryParams.has("uid") ? queryParams.get("uid") : null;
  const queryParamMarkComplete = queryParams.has("markComplete")
    ? parseInt(queryParams.get("markComplete"))
    : null;
  const [challengeData, setChallengeData] = useState(null);
  const [loadingChallenge, setLoadingChallenge] = useState(true);
  const [loadingUserChallenge, setLoadingUserChallenge] = useState(true);
  const [loadingParticipantsProgress, setLoadingParticipantsProgress] =
    useState(true);
  const [challengeParticipants, setChallengeParticipants] = useState(null);
  const [datesToChapters, setDatesToChapters] = useState(null);
  const [userChallengeData, setUserChallengeData] = useState(null);
  const [currentPortion, setCurrentPortion] = useState(0); // 1-indexed portion
  const [challengeParticipantsNames, setChallengeParticipantsNames] =
    useState(null);
  const [challengeParticipantsProgress, setChallengeParticipantsProgress] =
    useState(null);
  const [showParticipantsModal, setShowParticipantsModal] = useState(false);
  const { user } = useUserAuth();
  const [copied, setCopied] = useState(false);
  const [oneTimeAction, setOneTimeAction] = useState(true);
  const [alertDisplay, setAlertDisplay] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertVariant, setAlertVariant] = useState("success");

  const [addUserEmail, setAddUserEmail] = useState("");

  const [showConfirmLeave, setShowConfirmLeave] = useState(false);
  const handleCloseConfirmLeave = () => setShowConfirmLeave(false);
  const handleShowConfirmLeave = () => setShowConfirmLeave(true);

  const [showQRCodeModal, setShowQRCodeModal] = useState(false);
  const handleCloseQRCodeModal = () => setShowQRCodeModal(false);
  const handleShowQRCodeModal = () => setShowQRCodeModal(true);

  const FetchChallenge = async () => {
    const challengeDocRef = doc(db, "challenges", challengeID);
    const challengeDoc = await getDoc(challengeDocRef);
    if (!challengeDoc.exists()) {
      setLoadingChallenge(false);
      return;
    }
    const challengeData = challengeDoc.data();
    // load the participants
    const challengeParticipantsRef = collection(
      db,
      "challenges",
      challengeID,
      "users"
    );
    const challengeParticipantsSnap = await getDocs(challengeParticipantsRef);
    const challengeParticipantIds = [];
    challengeParticipantsSnap.docs.forEach((doc) => {
      challengeParticipantIds.push(doc.id);
    });
    setChallengeParticipants(challengeParticipantIds);
    setChallengeData(challengeData);
    setDatesToChapters(
      getDatesToChapters(
        challengeData.start_date,
        challengeData.end_date,
        challengeData.days,
        getReadingPortionsFromBooks(challengeData.books),
        parseDateList(challengeData.skip_dates)
      )
    );
    setLoadingChallenge(false);
  };

  const FetchUserChallenge = async () => {
    const uid = queryParamUID ? queryParamUID : user.uid;
    if (uid === null) {
      setUserChallengeData(null);
      setLoadingUserChallenge(false);
      return;
    }
    const userChallengeDataRef = doc(
      db,
      "users",
      uid,
      "challenges",
      challengeID
    );
    const userChallengeDataSnap = await getDoc(userChallengeDataRef);
    if (!userChallengeDataSnap.exists()) {
      setUserChallengeData(null);
      setLoadingUserChallenge(false);
      return;
    }
    const userChallengeData = userChallengeDataSnap.data();
    setUserChallengeData(userChallengeData);
    setLoadingUserChallenge(false);
  };

  const FetchParticipantsProgress = async () => {
    // A non-participant may not view the challenge participants according to the security rules
    if (user === null || user === undefined) {
      return;
    } else if (userChallengeData === null || userChallengeData === undefined) {
      return;
    }
    if (
      challengeParticipants === null ||
      challengeParticipants === undefined ||
      challengeParticipants.length === 0
    ) {
      return;
    }
    const names = [];
    const progress = [];
    for (const userID of challengeParticipants) {
      // get the user's display name
      const userDocRef = doc(db, "users", userID);
      const userDoc = await getDoc(userDocRef);
      if (!userDoc.exists()) {
        console.log("Warning: user in challenge does not exist: " + userID);
        continue;
      }
      const userData = userDoc.data();
      const userName = userData.displayName;
      // get the user's progress
      const userChallengeDataRef = doc(
        db,
        "users",
        userID,
        "challenges",
        challengeID
      );
      const userChallengeDataSnap = await getDoc(userChallengeDataRef);
      if (!userChallengeDataSnap.exists()) {
        console.log(
          "Warning: user in challenge does not have challenge data: " + userID
        );
        continue;
      }
      const userChallengeData = userChallengeDataSnap.data();
      const userProgress = userChallengeData.progress;
      names.push(userName);
      progress.push(userProgress);
    }
    setChallengeParticipantsNames(names);
    setChallengeParticipantsProgress(progress);
    setLoadingParticipantsProgress(false);
  };

  /**
   * Handle one-time actions that need to be performed when the page loads.
   * @returns {Promise<void>}
   * @constructor
   */
  const OneTimeAction = async () => {
    await createUserInDatabaseIfDoesNotExist(db, user);
    if (user != null && queryParamUID !== user.uid) {
      setAlertVariant("warning");
      setAlertMessage(
        <>
          <p style={{ display: "inline" }}>{"User updated, please click "}</p>
          <a
            style={{ display: "inline" }}
            href={"./challenge?id=" + challengeID}
          >
            {"here"}
          </a>
          <p style={{ display: "inline" }}>{" to continue"}</p>
        </>
      );
      setLoadingUserChallenge(true);
      setAlertDisplay(true);
    } else if (
      queryParamMarkComplete != null &&
      !isNaN(queryParamMarkComplete) &&
      queryParamUID != null
    ) {
      // mark a reading portion as complete based on the query parameters
      setAlertVariant("info");
      setAlertMessage("Marking reading complete...");
      setAlertDisplay(true);
      const ableToMark = await markReadingPortion(
        db,
        queryParamUID,
        challengeID,
        queryParamMarkComplete,
        true
      );
      // TODO update progresses in the "View Participants" button and the Leaderboard
      //  when marking a reading complete/incomplete
      if (ableToMark) {
        setAlertVariant("success");
        setAlertMessage("Marked reading complete!");
        setAlertDisplay(true);
        setLoadingUserChallenge(true);
      } else {
        setAlertVariant("warning");
        setAlertMessage(
          <>
            <p style={{ display: "inline" }}>{"User updated, please click "}</p>
            <a
              style={{ display: "inline" }}
              href={"./challenge?id=" + challengeID}
            >
              {"here"}
            </a>
            <p style={{ display: "inline" }}>{" to continue"}</p>
          </>
        );
        setAlertDisplay(true);
      }
    }
    setOneTimeAction(false);
  };

  useEffect(() => {
    FetchChallenge().then((r) => r);
  }, [user, loadingChallenge]);

  useEffect(() => {
    if (user == null && queryParamUID == null) {
      // need to be 'signed in' to view the challenge
      setLoadingUserChallenge(false);
    } else {
      FetchUserChallenge().then((r) => r);
    }
  }, [user, loadingUserChallenge]);

  useEffect(() => {
    FetchParticipantsProgress().then((r) => r);
  }, [challengeParticipants]);

  useEffect(() => {
    if (oneTimeAction) {
      OneTimeAction().then((r) => r);
    }
  }, []);

  const handleCloseParticipantsModal = () => setShowParticipantsModal(false);
  const handleShowParticipantsModal = () => setShowParticipantsModal(true);

  /**
   * Get the html elements for the reading pagination
   * @param datesToChapters the dates and chapters list, see output of getDatesToChapters in challenges.js
   * @param challengeProgress the user's challenge progress, a string of 0s and 1s
   */
  function getReadingPagination(datesToChapters, challengeProgress) {
    const pagination = [];
    for (let i = 0; i < datesToChapters.length; i++) {
      let portionBeforeCurrentTime =
        datesToChapters[i][0] < getCurrentLocalDate();
      const readChapter = challengeProgress.charAt(i) === "1";
      const late = portionBeforeCurrentTime && !readChapter;
      pagination.push(
        <Pagination.Item
          key={"page-item-" + (i + 1)}
          id={"page-item-" + (i + 1)}
          className={"align-content-center align-items-center text-center"}
        >
          <a
            className={
              "page-link " +
              (readChapter ? "text-success" : late ? "text-danger" : null) +
              (currentPortion === i + 1 ? " active" : "")
            }
            href={"#reading-pagination"}
            onClick={() => setCurrentPortion(i + 1)}
          >
            {"Day " + (i + 1).toString()}
            <br />
            {stringifyDateLocally(datesToChapters[i][0]) +
              (challengeProgress.charAt(i) === "1" ? " ✓" : "")}
          </a>
        </Pagination.Item>
      );
    }
    return <Pagination className="reading-pagination">{pagination}</Pagination>;
  }

  /**
   * Get the html elements for the current reading portion
   * Warning: assumes datesToChapters and currentPortion are not null
   */
  function getCurrentReadingPortion() {
    const chaptersToRead = datesToChapters[currentPortion - 1][1];
    const result = [];
    for (let i = 0; i < chaptersToRead.length; i++) {
      const [currentBook, currentChapter] = chaptersToRead[i];
      result.push(
        <h4>
          {capitalizeBook(currentBook)} {currentChapter}
        </h4>
      );
      const orderedList = [];
      const verses = getListOfVerses(currentBook, currentChapter);
      for (let j = 0; j < verses.length; j++) {
        orderedList.push(
          <li key={"li-chapter-" + currentChapter + "-verse-" + j}>
            {verses[j]}
          </li>
        );
      }
      result.push(<ol key={"ol-chapter-" + currentChapter}>{orderedList}</ol>);
    }
    return result;
  }

  function getChallengeSettings() {
    return (
      <>
        <p style={{ display: "inline" }}>Sharing: </p>
        <b style={{ display: "inline" }}>
          {challengeData?.is_public ? "Public" : "Private"}
        </b>
        <br />
        <label htmlFor="challenge-link" className="visually-hidden">
          Sharing Link
        </label>
        <input
          type="text"
          id="challenge-link"
          className="me-3 mt-3"
          style={{ display: "inline" }}
          value={"https://memorize.life/challenges?id=" + challengeID}
          readOnly={true}
        />
        <CopyToClipboard
          text={"https://memorize.life/challenges?id=" + challengeID}
          onCopy={() => setCopied(true)}
        >
          <Button variant="primary" style={{ display: "inline" }}>
            Copy Share Link
          </Button>
        </CopyToClipboard>
        {copied ? <p style={{ display: "inline" }}> Copied!</p> : null}
        <hr />
        <Button variant="primary" onClick={handleShowQRCodeModal}>
          Share QR Code
        </Button>
        <hr />
        {user === null ? (
          <p>
            <b
              onClick={logIn}
              style={{ border: "none", background: "none", color: "blue" }}
            >
              Sign in
            </b>{" "}
            to add participants by email.
          </p>
        ) : (
          <>
            <label htmlFor="add-participant-email" className="visually-hidden">
              Add Participant By Email
            </label>
            <input
              type="text"
              id="add-participant-email"
              className="me-3 mt-3"
              style={{ display: "inline" }}
              value={addUserEmail}
              onChange={(e) => setAddUserEmail(e.target.value.toLowerCase())}
              readOnly={false}
              placeholder="Add Participant By Email"
              autoCapitalize={"none"}
              autoComplete={"off"}
              autoCorrect={"off"}
            />
            <Button
              variant="primary"
              style={{ display: "inline" }}
              onClick={async () => {
                const email = addUserEmail.toLowerCase();
                console.log(
                  "Attempting to add " + email + " to challenge " + challengeID
                );
                // validate email with regex
                if (
                  !email.match(
                    "^([\\w-]+(?:\\.[\\w-]+)*)@((?:[\\w-]+\\.)*\\w[\\w-]{0,66})\\.([a-z]{2,6}(?:\\.[a-z]{2})?)$"
                  )
                ) {
                  alert("Invalid email address!");
                  return;
                }
                const [success, message] = await addUserToChallengeByEmail(
                  db,
                  challengeID,
                  email
                );
                alert(message);
              }}
            >
              <img
                src={add_person_image}
                alt="Add Participant"
                width="20"
                height="20"
              />
            </Button>
          </>
        )}
        <hr />
        {loadingChallenge === false &&
        loadingUserChallenge === false &&
        userChallengeData != null ? (
          <>
            <p>
              {userChallengeData.emailUpdates // also checks for userChallengeData !== null
                ? "You are receiving emails for this challenge (except for portions already completed)."
                : "You are not receiving emails for this challenge."}
            </p>
            <Button
              variant={userChallengeData.emailUpdates ? "danger" : "primary"}
              className={"my-2"}
              onClick={async () => {
                await updateGetEmails(
                  db,
                  user.uid,
                  challengeID,
                  !userChallengeData.emailUpdates
                );
                setLoadingUserChallenge(true);
              }}
            >
              {userChallengeData.emailUpdates // also checks for userChallengeData !== null
                ? "Unsubscribe"
                : "Subscribe"}
            </Button>
          </>
        ) : null}
      </>
    );
  }

  // console.log(getListOfVersesAndCopyrightMessage("genesis", 1));
  return (
    <Row className={"container-fluid"}>
      <Modal show={showConfirmLeave} onHide={handleCloseConfirmLeave}>
        <Modal.Header closeButton>
          <Modal.Title>Leave Challenge?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure that you would like to leave the challenge? Leaving the
          challenge will clear your progress!
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseConfirmLeave}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={async () => {
              await createUserInDatabaseIfDoesNotExist(db, user);
              await removeUserFromChallenge(db, user.uid, challengeID);
              setLoadingUserChallenge(true);
              setLoadingChallenge(true);
              handleCloseConfirmLeave();
            }}
          >
            Leave Challenge
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showQRCodeModal} onHide={handleCloseQRCodeModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            QR Code for Sharing Challenge: {challengeData?.challenge_name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col
              className={
                "justify-content-center text-center align-items-center"
              }
            >
              {challengeID === null ? null : (
                <QRCode
                  value={"https://memorize.life/challenge?id=" + challengeID}
                  style={{ maxWidth: "100%", maxHeight: "100%" }}
                />
              )}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseQRCodeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Col>
        {alertDisplay ? (
          <AlertComponent
            onClose={() => setAlertDisplay(false)}
            color={alertVariant}
            message={alertMessage}
          />
        ) : null}
        {user === null ||
        user === undefined ||
        challengeParticipants === null ||
        !challengeParticipants.includes(user.uid) ||
        loadingChallenge ||
        loadingUserChallenge ? null : (
          <Modal
            show={showParticipantsModal}
            onHide={handleCloseParticipantsModal}
            size={"lg"}
          >
            <Modal.Header closeButton>
              <Modal.Title>Challenge Participants</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {loadingParticipantsProgress ? (
                <p>Loading participant information</p>
              ) : (
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th scope={"col"}>Name</th>
                      <th scope={"col"}>Last Read</th>
                      <th scope={"col"}>Percent Complete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {challengeParticipantsNames.map((name, index) => {
                      return (
                        <tr>
                          <td>{name}</td>
                          <td>
                            {stringifyDateLocally(
                              getLastReadDate(
                                challengeParticipantsProgress[index],
                                datesToChapters
                              )
                            )}
                          </td>
                          <td>
                            {getPercentageCompleted(
                              challengeParticipantsProgress[index],
                              datesToChapters
                            ).toFixed(2)}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={handleCloseParticipantsModal}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
        <Row className={"container-fluid"}>
          <Col
            className={
              "col-12 col-md-7 text-start bg-primary bg-opacity-25 rounded m-2 p-3"
            }
          >
            {loadingChallenge === true ? (
              <>
                <p>Loading Challenge Details...</p>
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </>
            ) : challengeData === null ? (
              <div id="challenge-details-does-not-exist">
                <p>Challenge does not exist.</p>
              </div>
            ) : (
              <>
                <div id="challenge-details-header">
                  <Row>
                    <Col className={"col-8"}>
                      <h3 style={{ display: "inline" }}>Challenge: </h3>
                      <h3 style={{ display: "inline" }}>
                        {challengeData.challenge_name}
                      </h3>
                    </Col>
                    <Col
                      className={
                        "col-4 align-items-end text-end justify-content-end float-end"
                      }
                    >
                      <Button
                        variant="primary"
                        className={"d-md-none d-block"}
                        style={{ display: "inline" }}
                        data-toggle="collapse"
                        data-target="#challenge-details-collapse"
                        // data-bs-toggle="collapse"
                        // data-bs-target="#challenge-details-collapse"
                        aria-expanded="false"
                        aria-controls="challenge-details-collapse"
                        onClick={() => {
                          document
                            .getElementById("challenge-details-collapse")
                            .classList.toggle("show");
                        }}
                      >
                        <img
                          src={three_dots}
                          alt="Challenge Options"
                          width="20"
                          height="20"
                        />
                      </Button>
                    </Col>
                  </Row>
                </div>
                <Collapse
                  id="challenge-details-collapse"
                  className="collapse d-md-none rounded p-2"
                  style={{ backgroundColor: "#d5d5d5" }}
                >
                  <div>{getChallengeSettings()}</div>
                </Collapse>
                <br />
                <Row>
                  <Col className={"col-7"}>
                    <b>
                      {challengeData.books
                        .map((book) => capitalizeBook(book))
                        .join(", ")}
                    </b>
                    <br />
                    <b style={{ display: "inline" }}>
                      {stringifyDateLocally(datesToChapters[0][0])}
                    </b>
                    <p style={{ display: "inline" }}> - </p>
                    <b style={{ display: "inline" }}>
                      {stringifyDateLocally(
                        datesToChapters[datesToChapters.length - 1][0]
                      )}
                    </b>
                    <br />
                    <p style={{ display: "inline" }}>On </p>
                    <b style={{ display: "inline" }}>
                      {challengeData.days
                        .join(", ")
                        .replace("Sunday", "Lord's Day")}
                    </b>
                    <br />
                    <b style={{ display: "inline" }}>
                      {challengeData.books
                        .map((book) => verseCounts[book].length)
                        .reduce((a, b) => a + b, 0)}
                    </b>
                    <p style={{ display: "inline" }}> chapters</p>
                    <br />
                    <b style={{ display: "inline" }}>
                      {challengeParticipants?.length}
                    </b>
                    <p style={{ display: "inline" }}> participants</p>
                  </Col>
                  <Col className={"col-5 align-items-center text-center"}>
                    {loadingChallenge === false &&
                    loadingUserChallenge === false ? (
                      userChallengeData !== null &&
                      challengeParticipants != null &&
                      user !== null &&
                      challengeParticipants.includes(user.uid) ? (
                        <>
                          <Button
                            variant="danger"
                            className="my-2"
                            onClick={handleShowConfirmLeave}
                          >
                            Leave Challenge
                          </Button>
                        </>
                      ) : challengeData !== null && user !== null ? (
                        <Button
                          variant="primary"
                          className="my-2"
                          onClick={async () => {
                            setLoadingUserChallenge(true);
                            setLoadingChallenge(true);
                            await createUserInDatabaseIfDoesNotExist(db, user);
                            await addUserToChallenge(db, user.uid, challengeID);
                            setLoadingUserChallenge(false);
                            setLoadingChallenge(false);
                          }}
                        >
                          Join Challenge
                        </Button>
                      ) : null
                    ) : null}
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col
                    className={
                      "align-items-center justify-content-center text-center container-fluid"
                    }
                  >
                    <h4>Leaderboard</h4>
                    <ChallengeLeaderboard
                      challengeId={challengeID}
                      challengePortions={challengeData.reading_portions}
                    />

                    {loadingChallenge === true ? (
                      <>
                        <p>Loading participants...</p>
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </>
                    ) : user === null ||
                      user === undefined ||
                      challengeParticipants === null ||
                      !challengeParticipants.includes(user.uid) ? (
                      <p>
                        <b
                          onClick={logIn}
                          style={{
                            border: "none",
                            background: "none",
                            color: "blue",
                          }}
                        >
                          Sign in
                        </b>{" "}
                        and join this challenge to view all participants.
                      </p>
                    ) : (
                      <Button
                        variant="primary"
                        className={"my-2"}
                        onClick={handleShowParticipantsModal}
                      >
                        All Participants
                      </Button>
                    )}
                  </Col>
                </Row>
              </>
            )}
          </Col>
          <Col
            className={
              "col-12 col-md-4 bg-secondary bg-opacity-25 rounded m-2 p-3 d-none d-md-block"
            }
          >
            {getChallengeSettings()}
          </Col>
        </Row>
        <h3 className={"m-2"}>Your Reading</h3>
        <Button
          className={"m-2"}
          onClick={() => {
            if (datesToChapters === null || datesToChapters === undefined)
              return;
            let mostRecent = getMostRecentPortionIndex(datesToChapters);
            setCurrentPortion(mostRecent);
            // document
            //   .getElementById("challenge-reading")
            //   ?.scrollIntoView({ behavior: "smooth", block: "start" });
            document
              .getElementById("page-item-" + mostRecent)
              ?.scrollIntoView({ behavior: "smooth", block: "start" });
          }}
        >
          Go to Today's Reading
        </Button>
        <Button
          className={"m-2"}
          onClick={() => {
            if (userChallengeData === null || userChallengeData === undefined)
              return;
            let firstUnread = getFirstUnreadPortionIndex(
              userChallengeData.progress
            );
            setCurrentPortion(firstUnread);
            // document
            //     .getElementById("challenge-reading")
            //     ?.scrollIntoView({ behavior: "smooth", block: "start" });
            document
              .getElementById("page-item-" + firstUnread)
              ?.scrollIntoView({ behavior: "smooth", block: "start" });
          }}
        >
          Go to First Unread
        </Button>
        {loadingUserChallenge ? (
          <div>
            <p>Loading your reading...</p>
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : null}
        {!loadingUserChallenge && userChallengeData === null ? (
          <p>
            <b
              onClick={logIn}
              style={{ border: "none", background: "none", color: "blue" }}
            >
              Sign in
            </b>{" "}
            and join the challenge to view your reading.
          </p>
        ) : null}
        <div id="reading-pagination" className={"overflow-auto m-2"}>
          {!loadingUserChallenge &&
          userChallengeData !== null &&
          datesToChapters !== null
            ? getReadingPagination(datesToChapters, userChallengeData.progress)
            : null}
        </div>
        {!loadingUserChallenge &&
        userChallengeData !== null &&
        datesToChapters !== null &&
        currentPortion > 0 ? (
          <>
            <article
              id="challenge-reading"
              className={"container-fluid text-start"}
            >
              <h4>
                Reading for{" "}
                {stringifyDateLocally(datesToChapters[currentPortion - 1][0])}{" "}
                {userChallengeData.progress.charAt(currentPortion - 1) ===
                "0" ? (
                  <span className={"badge bg-warning"}>Unread</span>
                ) : (
                  <span className={"badge bg-success"}>Read</span>
                )}
              </h4>
              {getCurrentReadingPortion()}
            </article>
            <Copyright version={"rcv"} />
          </>
        ) : null}
        {!loadingUserChallenge &&
        userChallengeData !== null &&
        datesToChapters !== null &&
        currentPortion > 0 ? (
          userChallengeData.progress.charAt(currentPortion - 1) === "0" ? (
            <>
              <h4>
                {"Reading Portion: "}
                <span className={"badge bg-warning"}>Unread</span>
                <Button
                  variant="outline-success"
                  className={"mx-2"}
                  onClick={async () => {
                    await markReadingPortion(
                      db,
                      user.uid,
                      challengeID,
                      currentPortion,
                      true
                    );
                    setLoadingUserChallenge(true);
                    // wait for the loading to complete
                    while (loadingUserChallenge) {
                      await new Promise((resolve) => setTimeout(resolve, 100));
                    }
                    // scroll to the current portion
                    // console.log("scrolling to page-item-" + currentPortion);
                    document
                      .getElementById("page-item-" + currentPortion)
                      ?.scrollIntoView({ behavior: "smooth", block: "start" });
                    // console.log("scrolled to page-item-" + currentPortion);
                    // TODO update progresses on the page (challenge leaderboard and participants)
                  }}
                >
                  Mark Reading Complete
                </Button>
              </h4>
            </>
          ) : (
            <>
              <h4>
                {"Reading Portion: "}
                <span className={"badge bg-success"}>Read</span>
                <Button
                  variant="outline-danger"
                  className={"mx-2"}
                  onClick={async () => {
                    await markReadingPortion(
                      db,
                      user.uid,
                      challengeID,
                      currentPortion,
                      false
                    );
                    setLoadingUserChallenge(true);
                    // wait for the loading to complete
                    while (loadingUserChallenge) {
                      await new Promise((resolve) => setTimeout(resolve, 100));
                    }
                    // scroll to the current portion
                    // console.log("scrolling to page-item-" + currentPortion);
                    document
                      .getElementById("page-item-" + currentPortion)
                      ?.scrollIntoView({ behavior: "smooth", block: "start" });
                    // console.log("scrolled to page-item-" + currentPortion);
                    // TODO update progresses
                  }}
                >
                  Mark Reading Incomplete
                </Button>
              </h4>
            </>
          )
        ) : null}
        <ScrollToTop />
      </Col>
    </Row>
  );
};
