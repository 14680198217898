import React, { useState } from "react";
import { logIn, useUserAuth } from "../../UserAuth";
import { Button, Col, Row } from "react-bootstrap";
import { parseDateList, stringifyDateLocally } from "../../utils/date_utils";
import { getDatesToChapters } from "./challenges";
import {
  capitalizeBook,
  getReadingPortionsFromBooks,
} from "../../utils/bible_utils";
import { ScrollToTop } from "../utilities/ScrollToTop";
import { addDoc, collection, doc, getDoc, setDoc } from "firebase/firestore";
import { createUserInDatabaseIfDoesNotExist, db } from "../../firebase";

export const CreateChallenge = () => {
  const { user } = useUserAuth();
  const [currentStep, setCurrentStep] = useState(1);
  const [books, setBooks] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [days, setDays] = useState([]);
  const [datesToSkip, setDatesToSkip] = useState("");
  const [parsedDatesToSkip, setParsedDatesToSkip] = useState([]); // [Date]
  const [datesToChapters, setDatesToChapters] = useState([]); // {Date: [String]
  const [challengeName, setChallengeName] = useState("");
  const [isPublic, setIsPublic] = useState(false);
  const [creatingChallenge, setCreatingChallenge] = useState(false);
  const [createdChallengeID, setCreatedChallengeID] = useState(null);

  function validateStep1() {
    return books.length > 0;
  }

  function validateStep2() {
    if (endDate === "") {
      return (
        startDate !== "" &&
        days.length > 0 &&
        (datesToSkip === "" || parsedDatesToSkip.length > 0)
      );
    } else {
      // end date is after start date
      return (
        startDate !== "" &&
        endDate > startDate &&
        days.length > 0 &&
        (datesToSkip === "" || parsedDatesToSkip.length > 0)
      );
    }
  }

  function validateStep3() {
    return challengeName !== "";
  }

  function updatedValuesCallback() {
    console.log("Books: ", books);
    console.log("Start date: ", startDate === null ? "null" : startDate);
    console.log("End date: ", endDate === null ? "null" : endDate);
    console.log("days: ", days);
    console.log("skip dates: ", datesToSkip);
    console.log("challenge name: ", challengeName);
    console.log("Is public: ", isPublic);
    if (validateStep2()) {
      const readingPortions = getReadingPortionsFromBooks(books);
      setDatesToChapters(
        getDatesToChapters(
          startDate,
          endDate,
          days,
          readingPortions,
          parsedDatesToSkip
        )
      );
    }
  }

  async function createChallenge() {
    setCreatingChallenge(true);
    const challenge = {
      books: books,
      start_date: startDate,
      end_date: datesToChapters[datesToChapters.length - 1][0]
        .toISOString()
        .split("T")[0], // handle blank end date
      days: days,
      challenge_name: challengeName,
      skip_dates: datesToSkip,
      reading_portions: datesToChapters.length,
      is_public: isPublic,
    };
    console.log(challenge);
    // save the data to the firestore database
    const newChallengeRef = await addDoc(
      collection(db, "challenges"),
      challenge
    );
    // save the challenge to the active challenges list
    await setDoc(doc(db, "active_challenges", newChallengeRef.id), {});
    // add the user to the challenge's list of users
    await setDoc(
      doc(db, "challenges", newChallengeRef.id, "users", user.uid),
      {}
    );
    console.log("Challenge data created with ID: ", newChallengeRef.id);
    // add the challenge to the user's challenges
    let userDoc = await getDoc(doc(db, "users", user.uid));
    if (userDoc.exists()) {
      await setDoc(
        doc(db, "users", user.uid, "challenges", newChallengeRef.id),
        {
          progress: "0".repeat(datesToChapters.length),
          emailUpdates: true,
        }
      );
    } else {
      // create the user document
      await createUserInDatabaseIfDoesNotExist(db, user);
      // add the challenge to the user's challenges
      await setDoc(
        doc(db, "users", user.uid, "challenges", newChallengeRef.id),
        {
          progress: "0".repeat(datesToChapters.length),
          emailUpdates: true,
        }
      );
    }
    setCreatedChallengeID(newChallengeRef.id);
    setCreatingChallenge(false);
  }

  return (
    <Row className={"container-fluid"}>
      <Col>
        <Row className={"container-fluid"}>
          <div>
            <h1>Create Challenge</h1>
          </div>
          <div
            style={{
              display:
                creatingChallenge || createdChallengeID ? "none" : "block",
            }}
          >
            {user !== null ? (
              <form id="create-challenge-form">
                <div style={{ display: currentStep === 1 ? "block" : "none" }}>
                  <h4>Select Books to Read</h4>
                  <div className="form-group text-start">
                    <label htmlFor="books">
                      Select the books of the Bible to read. On a desktop, you
                      may hold shift to select a range of books.
                    </label>
                    <select
                      multiple
                      className="form-control my-2"
                      id="books"
                      required
                      style={{ height: "50vh" }}
                      onChange={(e) => {
                        const options = e.target.options;
                        const value = [];
                        for (let i = 0, l = options.length; i < l; i++) {
                          if (options[i].selected) {
                            value.push(options[i].value);
                          }
                        }
                        setBooks(value);
                      }}
                    >
                      <option value="genesis">Genesis</option>
                      <option value="exodus">Exodus</option>
                      <option value="leviticus">Leviticus</option>
                      <option value="numbers">Numbers</option>
                      <option value="deuteronomy">Deuteronomy</option>
                      <option value="joshua">Joshua</option>
                      <option value="judges">Judges</option>
                      <option value="ruth">Ruth</option>
                      <option value="1 samuel">1 Samuel</option>
                      <option value="2 samuel">2 Samuel</option>
                      <option value="1 kings">1 Kings</option>
                      <option value="2 kings">2 Kings</option>
                      <option value="1 chronicles">1 Chronicles</option>
                      <option value="2 chronicles">2 Chronicles</option>
                      <option value="ezra">Ezra</option>
                      <option value="nehemiah">Nehemiah</option>
                      <option value="esther">Esther</option>
                      <option value="job">Job</option>
                      <option value="psalms">Psalms</option>
                      <option value="proverbs">Proverbs</option>
                      <option value="ecclesiastes">Ecclesiastes</option>
                      <option value="song of songs">Song of Songs</option>
                      <option value="isaiah">Isaiah</option>
                      <option value="jeremiah">Jeremiah</option>
                      <option value="lamentations">Lamentations</option>
                      <option value="ezekiel">Ezekiel</option>
                      <option value="daniel">Daniel</option>
                      <option value="hosea">Hosea</option>
                      <option value="joel">Joel</option>
                      <option value="amos">Amos</option>
                      <option value="obadiah">Obadiah</option>
                      <option value="jonah">Jonah</option>
                      <option value="micah">Micah</option>
                      <option value="nahum">Nahum</option>
                      <option value="habakkuk">Habakkuk</option>
                      <option value="zephaniah">Zephaniah</option>
                      <option value="haggai">Haggai</option>
                      <option value="zechariah">Zechariah</option>
                      <option value="malachi">Malachi</option>
                      <option value="matthew">Matthew</option>
                      <option value="mark">Mark</option>
                      <option value="luke">Luke</option>
                      <option value="john">John</option>
                      <option value="acts">Acts</option>
                      <option value="romans">Romans</option>
                      <option value="1 corinthians">1 Corinthians</option>
                      <option value="2 corinthians">2 Corinthians</option>
                      <option value="galatians">Galatians</option>
                      <option value="ephesians">Ephesians</option>
                      <option value="philippians">Philippians</option>
                      <option value="colossians">Colossians</option>
                      <option value="1 thessalonians">1 Thessalonians</option>
                      <option value="2 thessalonians">2 Thessalonians</option>
                      <option value="1 timothy">1 Timothy</option>
                      <option value="2 timothy">2 Timothy</option>
                      <option value="titus">Titus</option>
                      <option value="philemon">Philemon</option>
                      <option value="hebrews">Hebrews</option>
                      <option value="james">James</option>
                      <option value="1 peter">1 Peter</option>
                      <option value="2 peter">2 Peter</option>
                      <option value="1 john">1 John</option>
                      <option value="2 john">2 John</option>
                      <option value="3 john">3 John</option>
                      <option value="jude">Jude</option>
                      <option value="revelation">Revelation</option>
                    </select>
                  </div>
                </div>
                <div style={{ display: currentStep === 2 ? "block" : "none" }}>
                  <h4>Choose Dates and Days</h4>
                  <div className="form-group text-start">
                    <label htmlFor="start-date">
                      <b>Start Date</b>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      id="start-date"
                      required
                      onChange={(e) => {
                        setStartDate(e.target.value);
                      }}
                    />
                    <p>
                      The start date is the first day of the challenge. Emails
                      will be sent starting from that day.
                    </p>
                  </div>
                  <div className="form-group text-start">
                    <label htmlFor="end-date">
                      <b>End Date</b>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      id="end-date"
                      onChange={(e) => {
                        setEndDate(e.target.value);
                      }}
                    />
                    <p>
                      The number of chapters will be arranged evenly across
                      days. If you leave this blank, the challenge will have one
                      chapter per day and the end date will be calculated
                      automatically.
                    </p>
                  </div>
                  <div className="form-group text-start">
                    <label htmlFor="days">
                      <b>Select days of the week:</b>
                    </label>
                    <select
                      multiple
                      className="form-control"
                      id="days"
                      required
                      onChange={(e) => {
                        const options = e.target.options;
                        const selectedDays = [];
                        for (let i = 0; i < options.length; i++) {
                          if (options[i].selected) {
                            selectedDays.push(options[i].value);
                          }
                        }
                        setDays(selectedDays);
                      }}
                    >
                      <option value="Monday">Monday</option>
                      <option value="Tuesday">Tuesday</option>
                      <option value="Wednesday">Wednesday</option>
                      <option value="Thursday">Thursday</option>
                      <option value="Friday">Friday</option>
                      <option value="Saturday">Saturday</option>
                      <option value="Sunday">Lord's Day</option>
                    </select>
                    <p>
                      Select days of the week on which you want to receive
                      emails. The reading challenge will only include the days
                      selected.
                    </p>
                  </div>
                  <div className="form-group text-start">
                    <label htmlFor="skip-dates">
                      <b>Dates to skip:</b>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="skip-dates"
                      onChange={(e) => {
                        setDatesToSkip(e.target.value);
                      }}
                    />
                    <p>
                      Enter dates to skip in the format
                      "YYYY-MM-DD...YYYY-MM-DD" or "YYYY-MM-DD". For example, to
                      skip November 1-7, 2016, you would enter
                      2016-11-01...2016-11-07. Separate multiple dates (or date
                      ranges) to skip with commas. If you leave this blank, no
                      dates will be skipped besides the days of the week that
                      are not selected.
                    </p>
                  </div>
                </div>
                <div style={{ display: currentStep === 3 ? "block" : "none" }}>
                  <h4>Name and Review Challenge</h4>
                  <div className="form-group text-start">
                    <label htmlFor="challenge-name">
                      <b>Challenge Name:</b>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="challenge-name"
                      required
                      onChange={(e) => {
                        setChallengeName(e.target.value);
                      }}
                    />
                    <p>
                      Enter a name for your challenge, for example "New
                      Testament in a Year". This will be used in the subject
                      line of the emails and in the title of the challenge page.
                    </p>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="public-challenge"
                      name="public-private"
                      value="public"
                      required
                      onChange={() => {
                        setIsPublic(true);
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="public-challenge"
                    >
                      Public Challenge
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="private-challenge"
                      name="public-private"
                      value="private"
                      defaultChecked={true}
                      onChange={() => {
                        setIsPublic(false);
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="private-challenge"
                    >
                      Private Challenge
                    </label>
                  </div>
                  <p>
                    A public challenge can be searched for by other users, and
                    they can join it.
                  </p>
                  <h4>Reading Schedule</h4>
                  <div
                    id="challenge-reading-schedule"
                    className="table-responsive-md"
                  >
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">Day</th>
                          <th scope="col">Chapters</th>
                        </tr>
                      </thead>
                      <tbody>
                        {datesToChapters
                          ? datesToChapters.map((dateToChapter, index) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    {stringifyDateLocally(dateToChapter[0])}
                                  </td>
                                  <td>
                                    {dateToChapter[1]
                                      .map(
                                        (chapter) =>
                                          capitalizeBook(chapter[0]) +
                                          " " +
                                          chapter[1]
                                      )
                                      .join(", ")}
                                  </td>
                                </tr>
                              );
                            })
                          : null}
                      </tbody>
                    </table>
                  </div>
                </div>

                <Button
                  variant={"primary"}
                  style={{ display: currentStep > 1 ? "block" : "none" }}
                  className={"my-2"}
                  onClick={() => setCurrentStep(currentStep - 1)}
                >
                  Back
                </Button>
                <Button
                  variant={"primary"}
                  style={{ display: currentStep < 3 ? "block" : "none" }}
                  className={"my-2"}
                  onClick={() => {
                    setParsedDatesToSkip(parseDateList(datesToSkip));
                    setCurrentStep(currentStep + 1);
                    updatedValuesCallback();
                  }}
                  disabled={
                    (currentStep === 1 && !validateStep1()) ||
                    (currentStep === 2 && !validateStep2())
                  }
                >
                  Next
                </Button>
                <Button
                  variant={"primary"}
                  style={{ display: currentStep === 3 ? "block" : "none" }}
                  className={"my-2"}
                  disabled={currentStep === 3 && !validateStep3()}
                  onClick={async () => {
                    await createChallenge();
                  }}
                >
                  Create Challenge
                </Button>
              </form>
            ) : (
              <p>
                <b
                  onClick={logIn}
                  style={{ border: "none", background: "none", color: "blue" }}
                >
                  Sign in
                </b>{" "}
                to create a challenge.
              </p>
            )}
          </div>
          {creatingChallenge ? (
            <div>
              <h2>Creating Challenge...</h2>
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : createdChallengeID ? (
            <div>
              <h2>Challenge Created!</h2>
              <p>Challenge created successfully!</p>
              <Button
                variant={"primary"}
                href={"./challenge?id=" + createdChallengeID}
              >
                View Challenge
              </Button>
            </div>
          ) : null}
          <ScrollToTop />
        </Row>
      </Col>
    </Row>
  );
};
