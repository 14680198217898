import { Switch, Route } from "react-router-dom";
import { HomePage } from "./components/HomePage";
import { PrivacyPolicy } from "./components/PrivacyPolicy";
import { DataRequest } from "./components/DataRequest";
import { ReadingChallenges } from "./components/ReadingChallenges";
import { Challenge } from "./components/challenges/Challenge";
import { CreateChallenge } from "./components/challenges/CreateChallenge";
import { Profile } from "./components/profile/Profile";
import { Leaderboard } from "./components/leaderboard/Leaderboard";

function Router() {
  return (
    <Switch>
      <Route exact path="/" component={HomePage} />
      <Route exact path="/read" component={ReadingChallenges} />
      <Route exact path={"/challenge"} component={Challenge} />
      <Route exact path={"/challenges"} component={Challenge} />
      {/*backwards compatibility*/}
      <Route exact path={"/challenge.html"} component={Challenge} />
      <Route exact path={"/create_challenge"} component={CreateChallenge} />
      <Route exact path={"/profile"} component={Profile} />
      <Route exact path={"/leaderboard"} component={Leaderboard} />
      <Route exact path={"/privacy-policy"} component={PrivacyPolicy} />
      <Route exact path={"/data-request"} component={DataRequest} />
    </Switch>
  );
}

export default Router;
