/**
 * @file date_utils.js
 * @description Utility functions for working with dates.
 */

import { dayNames } from "../constants";
import moment from "moment-timezone";

/**
 * Check if a given date is valid.
 * @param date {Date}
 * @returns {boolean}
 */
export function isValidDate(date) {
  return date instanceof Date && !isNaN(date);
}

/**
 * Compare two time strings of format "HH,MM", returning their difference in minutes.
 * @param time1 {string}
 * @param time2 {string}
 * @return {number}
 */
export function compareTimes(time1, time2) {
  const time1Split = time1.split(",").map(Number);
  const time2Split = time2.split(",").map(Number);
  const time1Minutes = time1Split[0] * 60 + time1Split[1];
  const time2Minutes = time2Split[0] * 60 + time2Split[1];
  return time1Minutes - time2Minutes;
}

/**
 * Get all valid days between startDate and endDate, inclusive, that are in daysOfWeek and not in dates_to_skip.
 * @param startDate {string}
 * @param endDate {string}
 * @param daysOfWeek {string[]}
 * @param dates_to_skip {Date[]}
 * @returns {*[]}
 */
export function getValidDays(startDate, endDate, daysOfWeek, dates_to_skip) {
  const validDays = [];
  let currentDate = new Date(startDate);
  if (endDate === "") {
    endDate = new Date(
      currentDate.getTime() +
        Math.ceil((1189 * 1000 * 60 * 60 * 24 * daysOfWeek.length) / 7) + // 1189 chapters in the Bible
        1000 * 60 * 60 * 24 * 7 + // 1 week just in case
        1000 * 60 * 60 * 24 * dates_to_skip.length // 1 day for each date to skip
    );
  } else {
    endDate = new Date(endDate);
  }
  while (currentDate <= endDate) {
    let should_skip = false;
    for (const date of dates_to_skip) {
      if (date.getTime() === currentDate.getTime()) {
        should_skip = true;
        break;
      }
    }
    if (should_skip) {
      currentDate.setUTCDate(currentDate.getUTCDate() + 1);
      continue;
    }
    if (dateIsInDaysOfWeek(currentDate, daysOfWeek)) {
      validDays.push(new Date(currentDate));
    }
    currentDate.setUTCDate(currentDate.getUTCDate() + 1);
  }
  return validDays;
}

/**
 * Get a selectable timezone in the dropdown from a given possible timezone.
 * @param timezone {string}
 * @returns {Promise<*>}
 */
export async function getSelectableTimezone(timezone) {
  // for each dictionary in assets/timezones.json, check if the dictionary's
  // "utc" list contains the given timezone. If so, check if any of the dictionary's
  // "utc" list elements are in assets/selectable_timezones.json. If so, return that
  // name
  let response = await fetch("assets/timezones.json");
  let timezones = await response.json();
  let response2 = await fetch("assets/selectable_timezones.json");
  let selectable_timezones = await response2.json();

  for (let i = 0; i < timezones.length; i++) {
    if (timezones[i].utc.includes(timezone)) {
      for (let j = 0; j < timezones[i].utc.length; j++) {
        if (selectable_timezones[timezones[i].utc[j]] !== undefined) {
          return timezones[i].utc[j];
        }
      }
    }
  }
}

/**
 * Return if a date (interpreted to UTC) is in a list of days of the week.
 * @param date
 * @param daysOfWeek
 * @returns {boolean}
 */
export function dateIsInDaysOfWeek(date, daysOfWeek) {
  const day = dayNames[new Date(date).getUTCDay()];
  return daysOfWeek.includes(day);
}

/**
 * Given a string of dates in the format "YYYY-MM-DD...YYYY-MM-DD,YYYY-MM-DD", return a list of Date objects that this
 * string represents.
 * @param dateList {string}
 * @returns {Date[]}
 */
export function parseDateList(dateList) {
  const dates = [];
  if (!dateList) {
    return dates;
  }
  const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
  const dateRangeRegex = /^\d{4}-\d{2}-\d{2}\.\.\.\d{4}-\d{2}-\d{2}$/;
  const dateStrings = dateList.split(",");
  for (const dateString of dateStrings) {
    if (dateRegex.test(dateString)) {
      const date = new Date(dateString);
      if (isValidDate(date)) {
        dates.push(date);
      } else {
        return [];
      }
    } else if (dateRangeRegex.test(dateString)) {
      const dateRange = dateString.split("...");
      const startDate = new Date(dateRange[0]);
      const endDate = new Date(dateRange[1]);
      if (isValidDate(startDate) && isValidDate(endDate)) {
        const currentDate = new Date(startDate);
        while (currentDate <= endDate) {
          dates.push(new Date(currentDate));
          currentDate.setUTCDate(currentDate.getUTCDate() + 1);
        }
      } else {
        return [];
      }
    } else {
      return [];
    }
  }
  return dates;
}

/**
 * Get the UTC time from a local hour and a timezone.
 * @param localHour {int} An hour in the desired timezone
 * @param timezone {string} Something like "America/New_York"
 * @returns {number[]} [hours, minutes]
 */
export function getUTCTimeFromLocalHourAndTimezone(localHour, timezone) {
  const now = moment();
  const localOffset = now.utcOffset();
  now.tz(timezone);
  const centralOffset = now.utcOffset();
  // console.log("Desired timezone offset to UTC in minutes: ", centralOffset);
  const diffInMinutes = (localOffset - centralOffset + 1440) % 1440;
  // console.log("Diff to desired timezone in minutes: ", diffInMinutes);
  const hours = Math.floor(diffInMinutes / 60);
  const minutes = diffInMinutes % 60;
  const localDate = new Date();
  localDate.setHours(localHour + hours);
  localDate.setMinutes(minutes);
  localDate.setSeconds(0);
  localDate.setMilliseconds(0);
  // console.log("UTC Hours: ", localDate.getUTCHours(), "UTC Minutes: ",  localDate.getUTCMinutes());
  return [localDate.getUTCHours(), localDate.getUTCMinutes()];
}

/**
 * Get a string in the format YYYY-MM-DD representing the current date in the local timezone.
 * @returns {string}
 */
export function getCurrentLocalDateString() {
  return new Date(new Date() - new Date().getTimezoneOffset() * 60000)
    .toISOString()
    .split("T")[0];
}

/**
 * Get a Date object representing the current date/time, but in the UTC timezone.
 * @returns {Date}
 */
export function getCurrentLocalDate() {
  return new Date(new Date() - new Date().getTimezoneOffset() * 60000);
}

/**
 * Get a Date object representing the current date/time, but in the UTC timezone, with the given day offset.
 * @param days
 * @returns {Date}
 */
export function getCurrentLocalDateWithDayOffset(days) {
  return new Date(
    new Date() - new Date().getTimezoneOffset() * 60000 + 1440 * 60000 * days
  );
}

/**
 * Convert a Date from the UTC timezone to the local timezone as a string.
 * @param date {Date}
 * @returns {string}
 */
export function stringifyDateLocally(date) {
  if (!date) return "";
  return date.toLocaleDateString(window.navigator.language, {
    timeZone: "UTC",
  });
}

/**
 * Get the date formatted as YYYY-MM-DD for a certain timezone right now, plus
 * an offset in days
 * @param{string} timezone
 * @param{int} daysOffset
 * @return {string}
 */
export function getFormattedDateForTimezone(timezone, daysOffset = 0) {
  const date = new Date();
  let dateInTimezone = new Date(
    date.toLocaleString("en-US", {
      timeZone: timezone,
    })
  );
  dateInTimezone = new Date(
    dateInTimezone -
      dateInTimezone.getTimezoneOffset() * 60000 +
      1440 * 60000 * daysOffset
  );
  return dateInTimezone.toISOString().split("T")[0];
}

/**
 * Get the date formatted as YYYY-MM-DD for the UTC timezone from a UTC date
 * @param{Date} date
 * @return {string}
 */
export function getFormattedDateFromUTC(date) {
  return date.toISOString().split("T")[0];
}
