import React, { useState } from "react";
import { Col, Container, Row, Alert } from "react-bootstrap";

export const AlertComponent = (props) => {
  return (
    <Container fluid className={"fixed-top"}>
      <Row className={"align-items-center justify-content-center text-center"}>
        <Col>
          {
            <Alert color={props.color} onClose={props.onClose} dismissible>
              {props.message}
            </Alert>
          }
        </Col>
      </Row>
    </Container>
  );
};
