import React, { useEffect, useState } from "react";
import { auth } from "../../firebase";
import { Button, Col, Row } from "react-bootstrap";
import { updateProfile } from "./profile_utils";
import { db } from "../../firebase";
import { doc } from "firebase/firestore";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { logIn } from "../../UserAuth";

export const Profile = () => {
  const [user] = useAuthState(auth);

  const userRef = user !== null ? doc(db, "users", user.uid) : null;
  const [userProfile] = useDocumentData(userRef);

  const [userData, setUserData] = useState(null);

  useEffect(() => {
    setUserData({
      displayName: userProfile?.displayName ?? "",
      timezone: userProfile?.timezone ?? "",
      emailTime: userProfile?.emailTime ?? "",
    });
  }, [userProfile]);
  console.log(userData);

  const onProfileChange = (field) => {
    return (e) => {
      setUserData((prevData) => {
        return {
          ...prevData,
          [field]: e.target.value,
        };
      });
    };
  };

  return (
    <Row className={"container-fluid"}>
      <Col>
        <Row className={"container-fluid"}>
          <Col className={"col-1 col-sm-2 col-md-3"} />
          <Col className={"col-10 col-sm-8 col-md-6"}>
            <div
              className={
                "align-items-center justify-content-center text-center"
              }
            >
              {user === null && userData !== undefined ? (
                <p>
                  <b
                    onClick={logIn}
                    style={{
                      border: "none",
                      background: "none",
                      color: "blue",
                    }}
                  >
                    Sign in
                  </b>{" "}
                  to see your profile.
                </p>
              ) : (
                <>
                  <div>
                    <h3>Display Name</h3>
                    <input
                      type="text"
                      placeholder="Display Name"
                      aria-label="Display Name"
                      aria-describedby="change-display-name-button"
                      defaultValue={userData?.displayName}
                      onChange={onProfileChange("displayName")}
                    />
                  </div>
                  <br />
                  <div>
                    <h3>Timezone</h3>
                    <select
                      className="form-select"
                      id="timezone-select"
                      aria-label="Timezone"
                      value={userData?.timezone}
                      onChange={onProfileChange("timezone")}
                      placeholder={"Select a timezone"}
                    >
                      <option value="Etc/GMT+12">
                        (GMT-12:00) International Date Line West
                      </option>
                      <option value="Pacific/Midway">
                        (GMT-11:00) Midway Island, Samoa
                      </option>
                      <option value="Pacific/Honolulu">
                        (GMT-10:00) Hawaii
                      </option>
                      <option value="US/Alaska">(GMT-09:00) Alaska</option>
                      <option value="America/Los_Angeles">
                        (GMT-08:00) Pacific Time (US & Canada)
                      </option>
                      <option value="America/Tijuana">
                        (GMT-08:00) Tijuana, Baja California
                      </option>
                      <option value="US/Arizona">(GMT-07:00) Arizona</option>
                      <option value="America/Chihuahua">
                        (GMT-07:00) Chihuahua, La Paz, Mazatlan
                      </option>
                      <option value="US/Mountain">
                        (GMT-07:00) Mountain Time (US & Canada)
                      </option>
                      <option value="America/Managua">
                        (GMT-06:00) Central America
                      </option>
                      <option value="US/Central">
                        (GMT-06:00) Central Time (US & Canada)
                      </option>
                      <option value="America/Mexico_City">
                        (GMT-06:00) Guadalajara, Mexico City, Monterrey
                      </option>
                      <option value="Canada/Saskatchewan">
                        (GMT-06:00) Saskatchewan
                      </option>
                      <option value="America/Bogota">
                        (GMT-05:00) Bogota, Lima, Quito, Rio Branco
                      </option>
                      <option value="America/New_York">
                        (GMT-05:00) Eastern Time (US & Canada)
                      </option>
                      <option value="Canada/Atlantic">
                        (GMT-04:00) Atlantic Time (Canada)
                      </option>
                      <option value="America/Caracas">
                        (GMT-04:00) Caracas, La Paz
                      </option>
                      <option value="America/Manaus">(GMT-04:00) Manaus</option>
                      <option value="America/Santiago">
                        (GMT-04:00) Santiago
                      </option>
                      <option value="Canada/Newfoundland">
                        (GMT-03:30) Newfoundland
                      </option>
                      <option value="America/Sao_Paulo">
                        (GMT-03:00) Brasilia
                      </option>
                      <option value="America/Argentina/Buenos_Aires">
                        (GMT-03:00) Buenos Aires, Georgetown
                      </option>
                      <option value="America/Godthab">
                        (GMT-03:00) Greenland
                      </option>
                      <option value="America/Montevideo">
                        (GMT-03:00) Montevideo
                      </option>
                      <option value="America/Noronha">
                        (GMT-02:00) Mid-Atlantic
                      </option>
                      <option value="Atlantic/Cape_Verde">
                        (GMT-01:00) Cape Verde Is.
                      </option>
                      <option value="Atlantic/Azores">
                        (GMT-01:00) Azores
                      </option>
                      <option value="Africa/Casablanca">
                        (GMT+00:00) Casablanca, Monrovia, Reykjavik
                      </option>
                      <option value="Etc/Greenwich">
                        (GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh,
                        Lisbon, London
                      </option>
                      <option value="Europe/Amsterdam">
                        (GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm,
                        Vienna
                      </option>
                      <option value="Europe/Belgrade">
                        (GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana,
                        Prague
                      </option>
                      <option value="Europe/Brussels">
                        (GMT+01:00) Brussels, Copenhagen, Madrid, Paris
                      </option>
                      <option value="Europe/Sarajevo">
                        (GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb
                      </option>
                      <option value="Africa/Lagos">
                        (GMT+01:00) West Central Africa
                      </option>
                      <option value="Asia/Amman">(GMT+02:00) Amman</option>
                      <option value="Europe/Athens">
                        (GMT+02:00) Athens, Bucharest, Istanbul
                      </option>
                      <option value="Asia/Beirut">(GMT+02:00) Beirut</option>
                      <option value="Africa/Cairo">(GMT+02:00) Cairo</option>
                      <option value="Africa/Harare">
                        (GMT+02:00) Harare, Pretoria
                      </option>
                      <option value="Europe/Helsinki">
                        (GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn,
                        Vilnius
                      </option>
                      <option value="Asia/Jerusalem">
                        (GMT+02:00) Jerusalem
                      </option>
                      <option value="Europe/Minsk">(GMT+02:00) Minsk</option>
                      <option value="Africa/Windhoek">
                        (GMT+02:00) Windhoek
                      </option>
                      <option value="Asia/Kuwait">
                        (GMT+03:00) Kuwait, Riyadh, Baghdad
                      </option>
                      <option value="Europe/Moscow">
                        (GMT+03:00) Moscow, St. Petersburg, Volgograd
                      </option>
                      <option value="Africa/Nairobi">
                        (GMT+03:00) Nairobi
                      </option>
                      <option value="Asia/Tbilisi">(GMT+03:00) Tbilisi</option>
                      <option value="Asia/Tehran">(GMT+03:30) Tehran</option>
                      <option value="Asia/Muscat">
                        (GMT+04:00) Abu Dhabi, Muscat
                      </option>
                      <option value="Asia/Baku">(GMT+04:00) Baku</option>
                      <option value="Asia/Yerevan">(GMT+04:00) Yerevan</option>
                      <option value="Asia/Kabul">(GMT+04:30) Kabul</option>
                      <option value="Asia/Yekaterinburg">
                        (GMT+05:00) Yekaterinburg
                      </option>
                      <option value="Asia/Karachi">
                        (GMT+05:00) Islamabad, Karachi, Tashkent
                      </option>
                      <option value="Asia/Calcutta">
                        (GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi, Sri
                        Jayawardenapura
                      </option>
                      <option value="Asia/Katmandu">
                        (GMT+05:45) Kathmandu
                      </option>
                      <option value="Asia/Almaty">
                        (GMT+06:00) Almaty, Novosibirsk
                      </option>
                      <option value="Asia/Dhaka">
                        (GMT+06:00) Astana, Dhaka
                      </option>
                      <option value="Asia/Rangoon">
                        (GMT+06:30) Yangon (Rangoon)
                      </option>
                      <option value="Asia/Bangkok">
                        (GMT+07:00) Bangkok, Hanoi, Jakarta
                      </option>
                      <option value="Asia/Krasnoyarsk">
                        (GMT+07:00) Krasnoyarsk
                      </option>
                      <option value="Asia/Hong_Kong">
                        (GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi
                      </option>
                      <option value="Asia/Kuala_Lumpur">
                        (GMT+08:00) Kuala Lumpur, Singapore
                      </option>
                      <option value="Asia/Irkutsk">
                        (GMT+08:00) Irkutsk, Ulaan Bataar
                      </option>
                      <option value="Australia/Perth">(GMT+08:00) Perth</option>
                      <option value="Asia/Taipei">(GMT+08:00) Taipei</option>
                      <option value="Asia/Tokyo">
                        (GMT+09:00) Osaka, Sapporo, Tokyo
                      </option>
                      <option value="Asia/Seoul">(GMT+09:00) Seoul</option>
                      <option value="Asia/Yakutsk">(GMT+09:00) Yakutsk</option>
                      <option value="Australia/Adelaide">
                        (GMT+09:30) Adelaide
                      </option>
                      <option value="Australia/Darwin">
                        (GMT+09:30) Darwin
                      </option>
                      <option value="Australia/Brisbane">
                        (GMT+10:00) Brisbane
                      </option>
                      <option value="Australia/Canberra">
                        (GMT+10:00) Canberra, Melbourne, Sydney
                      </option>
                      <option value="Australia/Hobart">
                        (GMT+10:00) Hobart
                      </option>
                      <option value="Pacific/Guam">
                        (GMT+10:00) Guam, Port Moresby
                      </option>
                      <option value="Asia/Vladivostok">
                        (GMT+10:00) Vladivostok
                      </option>
                      <option value="Asia/Magadan">
                        (GMT+11:00) Magadan, Solomon Is., New Caledonia
                      </option>
                      <option value="Pacific/Auckland">
                        (GMT+12:00) Auckland, Wellington
                      </option>
                      <option value="Pacific/Fiji">
                        (GMT+12:00) Fiji, Kamchatka, Marshall Is.
                      </option>
                      <option value="Pacific/Tongatapu">
                        (GMT+13:00) Nuku'alofa
                      </option>
                    </select>
                  </div>
                  <br />
                  <div>
                    <h3>Email Time</h3>
                    <select
                      className="form-select"
                      id="email-time-select"
                      aria-label="Email Time"
                      value={userData?.emailTime.toString()}
                      onChange={onProfileChange("emailTime")}
                    >
                      <option value="0">12:00 AM</option>
                      <option value="1">1:00 AM</option>
                      <option value="2">2:00 AM</option>
                      <option value="3">3:00 AM</option>
                      <option value="4">4:00 AM</option>
                      <option value="5">5:00 AM</option>
                      <option value="6">6:00 AM</option>
                      <option value="7">7:00 AM</option>
                      <option value="8">8:00 AM</option>
                      <option value="9">9:00 AM</option>
                      <option value="10">10:00 AM</option>
                      <option value="11">11:00 AM</option>
                      <option value="12">12:00 PM</option>
                      <option value="13">1:00 PM</option>
                      <option value="14">2:00 PM</option>
                      <option value="15">3:00 PM</option>
                      <option value="16">4:00 PM</option>
                      <option value="17">5:00 PM</option>
                      <option value="18">6:00 PM</option>
                      <option value="19">7:00 PM</option>
                      <option value="20">8:00 PM</option>
                      <option value="21">9:00 PM</option>
                      <option value="22">10:00 PM</option>
                      <option value="23">11:00 PM</option>
                    </select>
                  </div>
                </>
              )}
              <Button
                variant="primary"
                className="m-2"
                onClick={async () => {
                  console.log("userdata", userData);
                  await updateProfile(
                    db,
                    user,
                    userData?.displayName,
                    userData?.timezone,
                    parseInt(userData?.emailTime)
                  );
                }}
              >
                Save Changes
              </Button>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
