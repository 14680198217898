import { deleteDoc, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { getUTCTimeFromLocalHourAndTimezone } from "../../utils/date_utils";

export const updateProfile = async (
  db,
  user,
  displayName,
  timezone,
  emailTime
) => {
  await setUserDisplayName(db, user, displayName);
  await setUserTimezone(db, user, timezone);
  await setUserEmailTime(db, user, emailTime);
};

/**
 *
 * @param db
 * @param user
 * @param displayName
 * @returns {Promise<Boolean>}
 */
export async function setUserDisplayName(db, user, displayName) {
  if (!displayName) {
    return false;
  }
  // set the user's display name
  const userDoc = doc(db, "users", user.uid);
  const userDocSnapshot = await getDoc(userDoc);
  if (!userDocSnapshot.exists()) {
    return false;
  }
  // update the doc
  await updateDoc(userDoc, { displayName: displayName });
  return true;
}

/**
 * Set the user's timezone.
 * @param db {firebase.firestore.Firestore}
 * @param user {firebase.User}
 * @param timezone {string}
 * @returns {Promise<Boolean>}
 */
export async function setUserTimezone(db, user, timezone) {
  if (!timezone) {
    return false;
  }
  const userDoc = doc(db, "users", user.uid);
  // get the doc
  const userDocSnapshot = await getDoc(userDoc);
  if (!userDocSnapshot.exists()) {
    return false;
  }
  // get the emailTime
  const emailTime = userDocSnapshot.data().emailTime;
  const newGmtEmailTime = getUTCTimeFromLocalHourAndTimezone(
    emailTime,
    timezone
  ).toString();
  const oldGmtEmailTime = userDocSnapshot.data().gmtEmailTime;
  // update the user's timezone
  await updateDoc(userDoc, {
    timezone: timezone,
    gmtEmailTime: newGmtEmailTime,
  });
  // update the email_times collection
  const newEmailTimesDoc = doc(
    db,
    "email_times",
    newGmtEmailTime,
    "users",
    user.uid
  );
  await setDoc(newEmailTimesDoc, {});
  const oldEmailTimesDoc = doc(
    db,
    "email_times",
    oldGmtEmailTime,
    "users",
    user.uid
  );
  await deleteDoc(oldEmailTimesDoc);
  return true;
}

/**
 * Set the user's email time.
 * @param db {firebase.firestore.Firestore}
 * @param user {firebase.User}
 * @param emailTime {int}
 * @returns {Promise<Boolean>}
 */
export async function setUserEmailTime(db, user, emailTime) {
  if (!emailTime || isNaN(emailTime)) {
    return false;
  }
  const userDoc = doc(db, "users", user.uid);
  // get the doc
  const userDocSnapshot = await getDoc(userDoc);
  if (!userDocSnapshot.exists()) {
    return false;
  }
  // get the timezone
  const timezone = userDocSnapshot.data().timezone;
  const newGmtEmailTime = getUTCTimeFromLocalHourAndTimezone(
    emailTime,
    timezone
  ).toString();
  const oldGmtEmailTime = userDocSnapshot.data().gmtEmailTime;
  // update the user's email time
  await updateDoc(userDoc, {
    emailTime: emailTime,
    gmtEmailTime: newGmtEmailTime,
  });
  // update the email_times collection
  const newEmailTimesDoc = doc(db, "email_times", newGmtEmailTime);
  await setDoc(newEmailTimesDoc, {});
  const newEmailTimesUserDoc = doc(
    db,
    "email_times",
    newGmtEmailTime,
    "users",
    user.uid
  );
  await setDoc(newEmailTimesUserDoc, {});
  const oldEmailTimesUserDoc = doc(
    db,
    "email_times",
    oldGmtEmailTime,
    "users",
    user.uid
  );
  await deleteDoc(oldEmailTimesUserDoc);
  return true;
}

/**
 * Get the user's display name.
 * @param db {firebase.firestore.Firestore}
 * @param user {firebase.User}
 * @returns {Promise<string>}
 */
export async function getUserDisplayName(db, user) {
  if (user == null) {
    return null;
  }
  const userDoc = doc(db, "users", user.uid);
  const userDocSnapshot = await getDoc(userDoc);
  if (!userDocSnapshot.exists()) {
    return null;
  }
  return userDocSnapshot.data().displayName;
}
