import { BrowserRouter } from "react-router-dom";
import { Header } from "./components/Header";
import { UserAuthContextProvider } from "./UserAuth";
import Router from "./Router";

function App() {
  return (
    <UserAuthContextProvider>
      <BrowserRouter>
        <Header />
        <Router />
      </BrowserRouter>
    </UserAuthContextProvider>
  );
}

export default App;
