import React, { useEffect } from "react";
import { Button } from "react-bootstrap";

export const ScrollToTop = () => {
  const [scrollPosition, setScrollPosition] = React.useState(0);

  const handleScroll = () => {
    setScrollPosition(document.documentElement.scrollTop);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Button
      variant={"secondary"}
      style={{
        position: "fixed",
        width: "100px",
        bottom: "50px",
        right: "5vw",
        zIndex: "100",
        display:
          scrollPosition /
            (document.documentElement.scrollHeight -
              document.documentElement.clientHeight) >
          0.5
            ? "block"
            : "none",
        padding: "8px 4px",
        cursor: "pointer",
      }}
      onClick={() => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }}
    >
      Go to top ↑
    </Button>
  );
};
