import React from "react";

export const Copyright = (props) => {
  return (
    <div className={"container-fluid"}>
      <div
        className={
          "row container-fluid align-items-center justify-content-center text-center"
        }
        style={{ background: "white" }}
      >
        <div className="col">
          <div id="attribution">
            {props.version === "rcv" ? (
              <p>
                Holy Bible Recovery Version (text-only edition) © 2022 Living
                Stream Ministry www.lsm.org
              </p>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};
