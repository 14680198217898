import React, { useEffect, useState } from "react";
import { useUserAuth } from "../../UserAuth";
import { db } from "../../firebase";
import { collection, getDocs, doc, getDoc } from "firebase/firestore";

const first_place_trophy =
  require("../../assets/media/trophy-first.svg").default;
const second_place_trophy =
  require("../../assets/media/trophy-second.svg").default;
const third_place_trophy =
  require("../../assets/media/trophy-third.svg").default;

export const ChallengeLeaderboard = ({
  challengeId,
  challengeName,
  challengePortions,
}) => {
  const [rankings, setRankings] = useState([]);
  const [loadedChallenges, setLoadedChallenges] = useState(false);
  const { user } = useUserAuth();

  const FetchRankings = async () => {
    if (user === null) {
      return;
    }
    if (challengeId !== null) {
      const challengeUsersRef = collection(
        db,
        "challenges",
        challengeId,
        "users"
      );
      const challengeUsersDoc = await getDocs(challengeUsersRef);

      const challengeRanking = await Promise.all(
        challengeUsersDoc.docs.map(async (userDoc) => {
          const userId = userDoc.id;

          const [userProgressDoc, userNameDoc] = await Promise.all([
            getDoc(doc(db, "users", userId, "challenges", challengeId)),
            getDoc(doc(db, "users", userId)),
          ]);

          const binaryString = userProgressDoc.data().progress;
          const lastUpdated = userProgressDoc.data().lastUpdated
            ? userProgressDoc.data().lastUpdated
            : 0;
          const sum = Array.from(binaryString).reduce(
            (acc, digit) => (digit === "1" ? acc + 1 : acc),
            0
          );

          // console.log(userNameDoc.data());

          return {
            progress: sum,
            lastUpdated: lastUpdated,
            name:
              userNameDoc.data() != null
                ? userNameDoc.data().displayName
                : "Unnamed User",
          };
        })
      );

      setRankings(challengeRanking);
      setLoadedChallenges(true);
    }
  };

  useEffect(() => {
    FetchRankings();
  }, [user]);

  return (
    <>
      <div>
        <h3 style={{ marginTop: "10px" }}>
          {challengeName ? challengeName : null}
        </h3>
        <div className="text-start bg-primary bg-opacity-25 rounded mt-2 p-3">
          {user != null ? (
            loadedChallenges ? (
              rankings
                .sort((a, b) =>
                  a.progress === b.progress
                    ? a.lastUpdated - b.lastUpdated
                    : b.progress - a.progress
                )
                .map((rank, index) => (
                  <div key={index}>
                    {index === 0 ? (
                      <img
                        src={first_place_trophy}
                        alt={"First Place"}
                        style={{
                          width: "20px",
                          height: "20px",
                          display: "inline",
                        }}
                      />
                    ) : index === 1 ? (
                      <img
                        src={second_place_trophy}
                        alt={"Second Place"}
                        style={{
                          width: "20px",
                          height: "20px",
                          display: "inline",
                        }}
                      />
                    ) : index === 2 ? (
                      <img
                        src={third_place_trophy}
                        alt={"Third Place"}
                        style={{
                          width: "20px",
                          height: "20px",
                          display: "inline",
                        }}
                      />
                    ) : (
                      <></>
                    )}
                    <h5 style={{ display: "inline" }}> {rank.name}</h5>
                    <p>
                      Score: {rank.progress}/{challengePortions} (
                      {((rank.progress / challengePortions) * 100).toFixed(2)}
                      %)
                    </p>
                  </div>
                ))
                .slice(0, 5)
            ) : (
              <p>Loading rankings...</p>
            )
          ) : (
            <p>Log in to view rankings!</p>
          )}
        </div>
      </div>
    </>
  );
};
