import React from "react";
import { Button, Card, Col, Row } from "react-bootstrap";

export const PrivacyPolicy = () => {
  return (
    <div>
      <Row className={"container-fluid"}>
        <Col xs={0} md={2}></Col>
        <Col xs={12} md={8}>
          <Row className={"container-fluid"}>
            <Col>
              <h2>Privacy Policy</h2>
            </Col>
          </Row>
          <Row className={"container-fluid"}>
            <Col>
              <h3>
                <b>What data do we collect?</b>
              </h3>
              <p>
                memorize.life stores the data you provide when you create an
                account and use the site. This data includes your email address,
                password, name as provided by your login method, and reading
                challenge progress.
              </p>
              <h3>
                <b>How do we use your data?</b>
              </h3>
              <p>
                We do not share any personal data with anyone besides what is
                visible in the group reading features, and we do not use it for
                any purpose other than to provide you with the services on this
                site.
              </p>
              <p>
                We will never sell your data to anyone or any entity, for any
                reason.
              </p>
              <p>
                If you would like to change your displayed name, you can do so
                on the <a href="/profile">profile page</a>.
              </p>
              <h3>
                <b>How can you request a copy of your data?</b>
              </h3>
              <p>
                Go to the <a href="/data-request">data request page</a> and
                follow the instructions to request a copy of your data, or
                request that your data be deleted.
              </p>
              <h3>
                <b>How can you contact us?</b>
              </h3>
              <p>
                If you have any questions about memorize.life's privacy policy,
                the data we store, or if you would like to exercise one of your
                data protection rights, please do not hesitate to contact us at{" "}
                <a href={"mailto:mail@memorize.life"}>mail@memorize.life</a>.
              </p>
            </Col>
          </Row>
        </Col>
        <Col xs={0} md={2}></Col>
      </Row>
    </div>
  );
};
