import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useUserAuth } from "../../UserAuth";
import { db } from "../../firebase";
import { collection, getDocs, doc, getDoc } from "firebase/firestore";
import { ChallengeLeaderboard } from "./ChallengeLeaderboard";

export const Leaderboard = () => {
  const [challengeList, setChallengeList] = useState([]);
  const [loadedChallenges, setLoadedChallenges] = useState(false);
  const { user } = useUserAuth();

  const FetchChallenges = async () => {
    if (user !== null) {
      const userChallengesRef = collection(db, "users", user.uid, "challenges");
      const userChallengesDoc = await getDocs(userChallengesRef);

      const challengesPromises = userChallengesDoc.docs.map(async (doc) => {
        let data = await FetchChallengeData(doc.id);
        return {
          id: doc.id,
          name: data.challenge_name,
          portion: data.reading_portions,
        };
      });

      const challenges = await Promise.all(challengesPromises);

      setChallengeList(challenges);
      setLoadedChallenges(true);
    }
  };

  const FetchChallengeData = async (id) => {
    const challengeRef = doc(db, "challenges", id);
    const challengeDoc = await getDoc(challengeRef);

    return challengeDoc.data();
  };

  useEffect(() => {
    FetchChallenges();
  }, [user]);

  return (
    <Row className={"container-fluid"}>
      <Col>
        <div className="row container-fluid text-start">
          <div className="col-12">
            <h1>Bible Challenge Leaderboard</h1>
          </div>
        </div>
        <br />
        <div className="row container-fluid">
          <div className="col-12 col-md-7 text-start bg-primary bg-opacity-25 rounded m-2 p-3">
            {/* insert code here */}
            {loadedChallenges ? (
              challengeList.map((challenge) => (
                <ChallengeLeaderboard
                  key={challenge.id}
                  challengeName={challenge.name}
                  challengeId={challenge.id}
                  challengePortions={challenge.portion}
                />
              ))
            ) : (
              <p>Loading challenges...</p>
            )}
          </div>
        </div>
      </Col>
    </Row>
  );
};
