import React, { useEffect, useState } from "react";
import { ListGroup } from "react-bootstrap";
import { useUserAuth, logIn } from "../../UserAuth";
import { db } from "../../firebase";
import { collection, getDocs, doc, getDoc } from "firebase/firestore";

export const ChallengesList = () => {
  const [challenges, setChallenges] = useState([]);
  const [loadedChallenges, setLoadedChallenges] = useState(false);
  const { user } = useUserAuth();

  const FetchChallenges = async () => {
    if (user !== null && user.uid !== undefined) {
      const userChallengesRef = collection(db, "users", user.uid, "challenges");
      const userChallenges = await getDocs(userChallengesRef);
      const challengeIds = [];
      const challengeDatas = [];
      userChallenges.docs.forEach((doc) => {
        challengeIds.push(doc.id);
      });
      for (const challengeId of challengeIds) {
        let challengeDocRef = doc(db, "challenges", challengeId);
        let challengeDoc = await getDoc(challengeDocRef);
        const challengeData = challengeDoc.data();
        challengeData.id = challengeId;
        challengeDatas.push(challengeData);
      }
      setChallenges(challengeDatas);
      setLoadedChallenges(true);
    }
  };

  useEffect(() => {
    FetchChallenges();
  }, [user]);

  return user === null ? (
    <p>
      <b
        onClick={logIn}
        style={{ border: "none", background: "none", color: "blue" }}
      >
        Sign in
      </b>{" "}
      to see your challenges.
    </p>
  ) : loadedChallenges === false ? (
    <p>Loading your reading challenges...</p>
  ) : (
    <ListGroup>
      {challenges.length === 0 ? (
        <ListGroup.Item className="list-group-item list-group-item-action">
          You are not currently in any challenges.
        </ListGroup.Item>
      ) : (
        challenges.map((challenge) => {
          return (
            <ListGroup.Item
              action
              href={"/challenge?id=" + challenge.id}
              className="list-group-item list-group-item-action"
              key={"challenge-list-" + challenge.id}
            >
              {challenge.challenge_name}
            </ListGroup.Item>
          );
        })
      )}
    </ListGroup>
  );
};
