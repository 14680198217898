/**
 * @file firebase.js
 * @description Functions for working with Firebase.
 */

import { initializeApp } from "firebase/app";
import { getPerformance } from "firebase/performance";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import {
  getFirestore,
  connectFirestoreEmulator,
  doc,
  getDoc,
  collection,
  deleteDoc,
  addDoc,
  setDoc,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { getAuth, signInWithPopup } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getUTCTimeFromLocalHourAndTimezone } from "./utils/date_utils";
import {
  addUserToChallenge,
  removeUserFromChallenge,
} from "./components/challenges/challenges";
import { obscureEmail } from "./utils/string_utils";
import { getLCP, getFID, getCLS } from "web-vitals"; // for performance metrics

getCLS(console.log);
getFID(console.log);
getLCP(console.log);

const firebaseConfig = {
  apiKey: "AIzaSyBZh6UHfbJC9Vb21lBYNK82QKzkJ2-D4BE",
  authDomain: "memorize.life",
  databaseURL: "https://memorize-life-default-rtdb.firebaseio.com",
  projectId: "memorize-life",
  storageBucket: "memorize-life.appspot.com",
  messagingSenderId: "608680780787",
  appId: "1:608680780787:web:58d852024036f7715d2886",
  measurementId: "G-D3RN5DTQD9",
};

const app = initializeApp(firebaseConfig);

if (process.env.REACT_APP_ENV === "development") {
  global.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
}

export const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider("6LfbdbklAAAAABVTPDEG8WXZ8Q5hMZzVowpae6sO"),
  isTokenAutoRefreshEnabled: true,
});

export const perf = getPerformance(app);

export const db = getFirestore(app);
export const auth = getAuth(app);

export const storage = getStorage(app);

// connect to emulators
// if (process.env.REACT_APP_ENV === "development") {
//   connectFirestoreEmulator(db, "localhost", 8080);
// }

/**
 * Check if a given user's email belongs to a user in the database.
 * @param db {firebase.firestore.Firestore}
 * @param email {string}
 * @returns {Promise<boolean>}
 */
export async function checkIfEmailIsAUser(db, email) {
  // query users based on email
  const usersRef = collection(db, "users");
  const usersQuery = query(usersRef, where("email", "==", email));
  const usersQuerySnapshot = await getDocs(usersQuery);
  return !usersQuerySnapshot.empty;
}

/**
 * Get the UID of a user from their email. If there are multiple users with the
 * same email, return the one that is not the ignoreID.
 * @param db {Firestore}
 * @param email {string}
 * @param ignoreID {string}
 * @returns {Promise<*|null>}
 */
export async function getUIDfromEmail(db, email, ignoreID = null) {
  // query users based on email
  const usersRef = collection(db, "users");
  const usersQuery = query(usersRef, where("email", "==", email));
  const usersQuerySnapshot = await getDocs(usersQuery);
  if (usersQuerySnapshot.empty) {
    return null;
  } else if (usersQuerySnapshot.size > 1) {
    // if there are multiple users with the same email, return the one that is
    // not the ignoreID
    for (const doc of usersQuerySnapshot.docs) {
      if (doc.id !== ignoreID) {
        return doc.id;
      }
    }
  } else {
    return usersQuerySnapshot.docs[0].id;
  }
}

/**
 * Set up the email times document for a user (only used the first time a user
 * is added to the database).
 * @param db {Firestore}
 * @param gmtEmailTime {string} e.g. "11,0"
 * @param user {firebase.User}
 * @returns {Promise<void>}
 */
async function setEmailTimesDocumentFirstTime(db, gmtEmailTime, user) {
  const emailTimesDoc = doc(db, "email_times", gmtEmailTime);
  const emailTimesDocSnapshot = await getDoc(emailTimesDoc);
  if (!emailTimesDocSnapshot.exists()) {
    await setDoc(emailTimesDoc, {});
  }
  const emailTimesUserDoc = doc(
    db,
    "email_times",
    gmtEmailTime,
    "users",
    user.uid
  );
  const emailTimesUserDocSnapshot = await getDoc(emailTimesUserDoc);
  if (!emailTimesUserDocSnapshot.exists()) {
    await setDoc(emailTimesUserDoc, {});
  }
}

/**
 * Create a user in the database if they don't already exist. Also upgrades a temporary user to a permanent user.
 * @param db {Firestore}
 * @param user {firebase.User}
 * @returns {Promise<void>}
 */
export async function createUserInDatabaseIfDoesNotExist(db, user) {
  if (!user) {
    return;
  }
  // given an auth.user object, create a user in the database if it doesn't yet exist
  const userDoc = doc(db, "users", user.uid);
  const userDocSnapshot = await getDoc(userDoc);
  if (!userDocSnapshot.exists()) {
    // check if the user was already added as a temporary user, if so, transfer
    // the challenges from the temporary user to the new user at the end
    const oldUID = await getUIDfromEmail(db, user.email);
    const gmtEmailTime = getUTCTimeFromLocalHourAndTimezone(
      7,
      Intl.DateTimeFormat().resolvedOptions().timeZone
    ).toString();
    await setDoc(userDoc, {
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      emailTime: 7,
      gmtEmailTime: gmtEmailTime,
      temporary: false,
    });
    await setEmailTimesDocumentFirstTime(db, gmtEmailTime, user);
    if (oldUID !== null) {
      // get all challenges in the temporary user's challenges collection
      const challengesCollection = collection(
        db,
        "users",
        oldUID,
        "challenges"
      );
      const challengesCollectionSnapshot = await getDocs(challengesCollection);
      const challenges = [];
      challengesCollectionSnapshot.forEach((doc) => {
        challenges.push(doc);
      });
      // add the old challenges to the new user's challenges collection
      for (const challenge of challenges) {
        // add user to the challenge
        await addUserToChallenge(db, user.uid, challenge.id);
        // update the information of the progresses
        await setDoc(
          doc(db, "users", user.uid, "challenges", challenge.id),
          challenge.data()
        );
        // remove the old user from the challenge cannot be done because of security rules. we have to do it with a cloud function
        await removeUserFromChallenge(db, oldUID, challenge.id);
      }
      // remove the old users gmtemailtime from the email_times collection
      const oldUserDoc = doc(db, "users", oldUID);
      const oldUserDocSnapshot = await getDoc(oldUserDoc);
      const oldUserGmtEmailTime = oldUserDocSnapshot.data().gmtEmailTime;
      await deleteDoc(
        doc(db, "email_times", oldUserGmtEmailTime, "users", oldUID)
      );
      // delete the old user
      await deleteDoc(oldUserDoc);
    }
  } else {
    const userData = userDocSnapshot.data();
    // check if userData contains all the keys necessary
    const userDataKeys = [
      "email",
      "displayName",
      "photoURL",
      "timezone",
      "emailTime",
      "gmtEmailTime",
      "temporary",
    ];
    const userDataKeysExist = userDataKeys.every((key) =>
      userData.hasOwnProperty(key)
    );
    if (!userDataKeysExist) {
      // add the missing keys
      if (!userData.hasOwnProperty("email")) {
        userData["email"] = user.email;
      }
      if (!userData.hasOwnProperty("displayName")) {
        userData["displayName"] = user.displayName;
      }
      if (!userData.hasOwnProperty("photoURL")) {
        userData["photoURL"] = user.photoURL;
      }
      if (!userData.hasOwnProperty("timezone")) {
        userData["timezone"] = Intl.DateTimeFormat().resolvedOptions().timeZone;
      }
      if (!userData.hasOwnProperty("emailTime")) {
        userData["emailTime"] = 7;
      }
      if (!userData.hasOwnProperty("gmtEmailTime")) {
        const gmtEmailTime = getUTCTimeFromLocalHourAndTimezone(
          userData["emailTime"],
          userData["timezone"]
        ).toString();
        userData["gmtEmailTime"] = gmtEmailTime;
        await setEmailTimesDocumentFirstTime(db, gmtEmailTime, user);
      }
      if (!userData.hasOwnProperty("temporary")) {
        userData["temporary"] = false;
      }
      await setDoc(userDoc, userData);
    }
  }
}

/**
 * Create a temporary user from an email address.
 * @param db
 * @param email
 * @returns {Promise<*>}
 */
export async function createTemporaryUserFromEmail(db, email) {
  // create a temporary user. return the user ID
  const gmtEmailTime = getUTCTimeFromLocalHourAndTimezone(
    7,
    Intl.DateTimeFormat().resolvedOptions().timeZone
  ).toString();
  const userData = {
    email: email,
    displayName: obscureEmail(email),
    photoURL: null,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    emailTime: 7,
    gmtEmailTime: gmtEmailTime,
    temporary: true,
  };
  const docRef = await addDoc(collection(db, "users"), userData);
  // add the user to the email_times collection, so they can get emails at this time
  const emailTimesDoc = doc(db, "email_times", gmtEmailTime);
  const emailTimesDocSnapshot = await getDoc(emailTimesDoc);
  if (!emailTimesDocSnapshot.exists()) {
    await setDoc(emailTimesDoc, {});
  }
  const emailTimesUserDoc = doc(
    db,
    "email_times",
    gmtEmailTime,
    "users",
    docRef.id
  );
  const emailTimesUserDocSnapshot = await getDoc(emailTimesUserDoc);
  if (!emailTimesUserDocSnapshot.exists()) {
    await setDoc(emailTimesUserDoc, {});
  }

  return docRef.id;
}
